import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Grid, Link, Paper, Typography, Card, CardHeader, CardContent, Avatar, Stepper, Step, StepLabel, FormControl, InputLabel, FormControlLabel, Checkbox, List, MenuItem, Input, Select, FormHelperText, RadioGroup, Radio, TextField, FormLabel, FormGroup } from '@material-ui/core'
import assigment from '../../../global/imagenes/assigment.png';
import * as Funciones from '../../../global/js/funciones';
import emma_w from '../../../global/imagenes/emma_w.png';
import LinearProgress from '@material-ui/core/LinearProgress';
// import CursoStepperEstu from './Cursos/CursoStepperEstu';
import TareasTerminadas from '../../general/TareasTerminadas';
import AppBarModal from '../../general/AppBarModal';
import { BtnCargar, BtnForm } from '../../general/BotonesAccion';
import * as Helper from './Helper';
import { Alert } from '@material-ui/lab';
import EnviarMultiplesArchivos from '../../general/EnviarMultiplesArchivos';

//Estilos para el Stepper necesarios para funcionar.
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorWhite: {
    color: '#fff'
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexCenter: {
    textAlign: "center",
    marginTop: "5px"
  },
  formControl: {
    margin: "0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  colorEmmma: {
    color: "#c79e32",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#c79e32",
      transform: "scale(0.8)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
  colorEdit: {
    color: "#0074C5",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#0074C5",
      transform: "scale(1.3)",
    },
    texto: {
      textAlign: 'justify'
    },
  }
}));


export default function FormCursos(props) {

  const [cargandoF, setCargandoF] = React.useState(true);


  const classes = useStyles();

  //useEffect para consumir las apis cuando cargue el componente.
  React.useEffect(() => {
    ObtenerDatosIniciales()

  }, [])



  const ObtenerDatosIniciales = async () => {
    setCargandoF(true)
    await obtenerInfoUser()
    await obtenerTipoIdentificacion()
    await obtenerSemilleros()
    await obtenerRequisito()
    await obtenerCursoFormacion()
    setCargandoF(false)
  }


  //Obtengo los departamentos con la funcion obtenerValores de funciones.js
  const obtenerInfoUser = async () => {
    try {
      const data = await Funciones.obtenerMisDatos()
      if (data.persona.primer_nombre == null) {
        props.setPrimernombre("")
      } else {
        props.setPrimernombre(data.persona.primer_nombre)
      }
      if (data.persona.segundo_nombre == null) {
        props.setSegundonombre("")
      } else {
        props.setSegundonombre(data.persona.segundo_nombre)
      }
      if (data.persona.primer_apellido == null) {
        props.setPrimerapellido("")
      } else {
        props.setPrimerapellido(data.persona.primer_apellido)
      }
      if (data.persona.segundo_apellido == null) {
        props.setSegundoapellido("")
      } else {
        props.setSegundoapellido(data.persona.segundo_apellido)
      }
      if (data.persona.identificacion == null) {
        props.setIdentificacion("")
      } else {
        props.setIdentificacion(data.persona.identificacion)
      }
      if (data.persona.tipo_identificacion == null) {
        props.setTipoidentificacion("")
      } else {
        props.setTipoidentificacion(data.persona.tipo_identificacion)
      }
      if (data.persona.celular == null) {
        props.setCelular("")
      } else {
        props.setCelular(data.persona.celular)
      }

      if (data.persona.telefono == null) {
        props.setTelefono("")
      } else {
        props.setTelefono(data.persona.telefono)
      }

      if (data.persona.correo == null) {
        props.setCorreo("")
      } else {
        props.setCorreo(data.persona.correo)
      }

      if (data.programas.length != 0) {
        props.setPrograma(data.programas)
      }

    } catch (err) {
      console.log(err)
    }
  }

  const obtenerTipoIdentificacion = async () => {
    try {
      const data = await Funciones.obtenerValores(Helper.TIPO_IDENTIFICACION)
      props.setTiposidentificacion(data)

      props.setCargando(false)
    } catch (e) {
      console.log(e)
    }
  }

  const obtenerRequisito = async () => {
    try {
      const data = await Funciones.obtenerValores(Helper.REQUISITO_CURSOS)
      props.setRequisito(data)

      props.setCargando(false)
    } catch (e) {
      console.log(e)
    }
  }

  const obtenerCursoFormacion =async () =>{
    try{
      const data= await Funciones.obtenerValores(Helper.GRUPOS_CURSOS)
      console.log('Datos obtenidos:', data);
      // Transforma los datos a la estructura deseada
    const cursosTransformados = data.map(curso => ({
      grupo: curso.valora,   // Usar valora para el nombre del grupo
      horario: curso.valorb  // Usar valorb para el horario
    }));

    // Actualiza el estado con los datos transformados
    props.setCursosFormacion(cursosTransformados);
    props.setCargando(false);
  } catch (e) {
    console.log(e);
    }
    }
  
  const obtenerSemilleros = async () => {
    try {
      const data = await obtenerSemillerosSolicitudes()
      console.log(data.length);
      props.setListSemilleros(data)

      props.setCargando(false)
    } catch (e) {
      console.log(e)
    }
  }

  const obtenerSemillerosSolicitudes = async () => {
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/investigacion/ObtenerSemillero2?solicitud__estado_actual__codigo=Semi_activo`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const pintarSteps = () => {
    if (props.cargando == true) return <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
    else return (
      <div className={classes.root}>
        <Stepper activeStep={props.activeStep} alternativeLabel>
          {props.steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
          {props.activeStep === props.steps.length ? (
            <div>
              <Typography className={classes.instructions}>{props.activeStep}</Typography>
              <Button onClick={props.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              {!cargandoF ?
                getStepContent(props.activeStep, props) :
                <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
              }
            </div>
          )}
        </div>
      </div>
    )
  }




  return (
    <div>

      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth={true} className={props.style_classes.margin}>
        <AppBarModal titulo='Formulario Seminario de Investigación Adjunto' mostrarModal={() => { props.handleClose() }} titulo_accion="" accion="" />
        <DialogContent>

          {props.cargandoFuera == true ? <><TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></> :
            pintarSteps()
          }

        </DialogContent>
        <DialogActions>
          <div>
            <Button disabled={props.activeStep === 0 || props.cargando == true} onClick={props.handleBack} >
              Atras
            </Button>
            {
              !(props.activeStep === props.steps.length - 1) ? <Button disabled={props.cargando == true} variant="contained" color="primary" style={{ color: '#fff' }} onClick={props.handleNext}>Siguiente</Button> :
                <BtnForm texto="GUARDAR" disabled={props.cargandoFuera == true} variant="contained" color="primary" style={{ color: "#fff" }} callback={() => { document.getElementById('enviar_form_multi_archivo').click() }}>Enviar</BtnForm>
            }
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}



//Aqui es donde se retorna el contenido de esa opcion del Stepper, osea los formularios.
function getStepContent(stepIndex, props) {

  switch (stepIndex) {
    case 0:
      return (
        <Form1
          hola={props.activeStep}
          steps={props.steps}
          handleReset={props.handleReset}
          //Inputs
          primernombre={props.primernombre}
          setPrimernombre={props.setPrimernombre}
          segundonombre={props.segundonombre}
          setSegundonombre={props.setSegundonombre}
          primerapellido={props.primerapellido}
          setPrimerapellido={props.setPrimerapellido}
          segundoapellido={props.segundoapellido}
          setSegundoapellido={props.setSegundoapellido}
          identificacion={props.identificacion}
          setIdentificacion={props.setIdentificacion}
          tiposidentificacion={props.tiposidentificacion}
          setTiposidentificacion={props.setTiposidentificacion}
          cursosFormacion={props.cursosFormacion}   
          setCursosFormacion={ props.setCursosFormacion}
          tipoidentificacion={props.tipoidentificacion}
          setTipoidentificacion={props.setTipoidentificacion}
          celular={props.celular}
          telefono={props.telefono}
          correo={props.correo}
          programa={props.programa}
          autorizo={props.autorizo}
          setAutorizo={props.setAutorizo}
          fechainscripcion={props.fechainscripcion}
					fechainiciocurso={props.fechainiciocurso}
          setFechainscripcion={props.setFechainscripcion}
					setFechainiciocurso={props.setFechainiciocurso}
          linkinformacion={props.linkinformacion}
          setLinkinformacion={props.setLinkinformacion}

          setCelular={props.setCelular}
          setTelefono={props.setTelefono}
          setCorreo={props.setCorreo}
          setPrograma={props.setPrograma}

          //Errores
          getError={props.getError}
          errores={props.errores}
          setErrores={props.setErrores}

          validateInputs={props.validateInputs}
          //Cargando
          cargando={props.cargando}
          setCargando={props.setCargando}

          programaSelect={props.programaSelect}
          setProgramaSelect={props.setProgramaSelect}
          semestreSeleccionado={props.semestreSeleccionado}
          setSemestreSeleccionado={props.setSemestreSeleccionado}
          handleClose={props.handleClose}

        />
      );
    case 1:
      return (
        <Form2
          isChecked1={props.isChecked1}
          isChecked2={props.isChecked2}
          setIsChecked1={props.setIsChecked1}
          setIsChecked2={props.setIsChecked2}
          listSemilleros={props.listSemilleros}
          setListSemilleros={props.setListSemilleros}
          semilleroSelect={props.semilleroSelect}
          setSemilleroSelect={props.setSemilleroSelect}
          horarioSelect={props.horarioSelect}
          setHorarioSelect={props.setHorarioSelect}
          cursosFormacion={props.cursosFormacion}
          getError={props.getError}
          errores={props.errores}
          setErrores={props.setErrores}
        />
      );
    case 2:
      return (
        <Requisitos
          requisito={props.requisito}
          setRequisito={props.setRequisito}
          submit={props.submit}
        />
      );

    default:
      return 'Unknown stepIndex';
  }
}

const Form1 = function (props) {

  const classes = useStyles();
  let semestres = 10

  //Funcion para asginar Departamentos y Programas al select.
  const handleChangeDepaPro = (event) => {
    props.setProgramaSelect(event.target.value);
    if (props.errores.length > 0) {
      props.setErrores(props.errores.filter(er => er.llave != "programa"));
      // Elimina error cuando se escribe en el campo
    }
  };

  const handleOnchangeSemestre = (event) => {
    const nuevoSemestreSeleccionado = event.target.value;
    props.setSemestreSeleccionado(nuevoSemestreSeleccionado);
  };

  const handleOnchangeAutorizar = (event) => {
    const opcion = event.target.value;
    if(opcion == 2){
      props.handleClose()
    }
    console.log(opcion)
    props.setAutorizo(opcion);
  };
  //Metodos OnChange para la escritura de los inputs.
  const handleOnchange = (e) => {
    if (e.target.name == "primernombre") {
      props.setPrimernombre(e.target.value)
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter(er => er.llave != "primernombre"));
        // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "telefono") {
      props.setTelefono(e.target.value)
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter(er => er.llave != "telefono"));
      }
    } /* else if (e.target.name == "correo") {
      props.setCorreo(e.target.value)
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter(er => er.llave != "correo"));
      }
    }  */else if (e.target.name == "cvlac") {
      props.setCvlac(e.target.value)
    } else if (e.target.name == "scholar") {
      props.setScholar(e.target.value)
    } else if (e.target.name == "researchGate") {
      props.setResearchGate(e.target.value)
    } else if (e.target.name == "academiaEdu") {
      props.setAcademiaEdu(e.target.value)
    } else if (e.target.name == "experiencia") {
      props.setExperiencia(e.target.value)
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter(er => er.llave != "experiencia"));
      }
    } else if (e.target.name == "adj_seminario") {
      props.setAdjSeminario(e.target.value)
      if (props.errores.length > 0) {
        props.setErrores(props.errores.filter(er => er.llave != "adj_metodologia"));
        // Elimina error cuando se escribe en el campo
      }
    } else if (e.target.name == "adj_metodologia") {
      props.setAdjMetodologia(e.target.value)
    }

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info" >
          {/* fecha de inscripción: 20 de julio 2023 <br /> */}
          Plazo máximo de inscripción: {props.fechainscripcion} <br />
          Fecha de comienzo del curso: {props.fechainiciocurso} <br/>
          Para más información acerca del curso,{' '}
          <a href={props.linkinformacion} target="_blank" rel="noopener noreferrer">
            ingresa aquí
          </a> 
        </Alert>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Primer Nombre"
          id='primernombre'
          value={props.primernombre}
          onChange={handleOnchange}
          name="primernombre" />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Segundo Nombre"
          id='segundonombre'
          value={props.segundonombre}
          onChange={handleOnchange}
          name="segundonombre" />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Primer Apellido"
          id='primerapellido'
          value={props.primerapellido}
          onChange={handleOnchange}
          name="primerapellido" />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Segundo Apellido"
          id='segundoapellido'
          value={props.segundoapellido}
          onChange={handleOnchange}
          name="segundoapellido" />
      </Grid>
      <Grid item xs={6}>
        <FormControl className='form-control' error={props.getError('tipoidentificacion', props.errores).length > 0}>
          <InputLabel>Tipo de documento</InputLabel>
          <Select
            name='tipoidentificacion'
            value={props.tipoidentificacion}
            onChange={handleOnchange}
          >
            {
              props.tiposidentificacion.map((ti) => (
                <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormHelperText>{props.getError('tipoidentificacion', props.errores)}</FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Número de Documento de Identidad"
          id='identificacion'
          error={props.getError('identificacion', props.errores).length > 0}
          helperText={props.getError('identificacion', props.errores)}
          value={props.identificacion}
          onChange={handleOnchange}
          name="identificacion" />
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="age-native-simple" >Programa académico al que pertenece </InputLabel>
        <FormControl className={classes.formControl} fullWidth error={props.getError('programa', props.errores).length > 0}>
          <Select
            value={props.programaSelect}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleChangeDepaPro}
          >
            <MenuItem value="">
              <em style={{ color: "rgba(0, 0, 0, 0.54)" }}>{`${props.programa.length} Progra. Seleccionados`}</em>
            </MenuItem>
            {props.programa.map((item, index) => (
              <MenuItem key={index} value={item.relacion.id}>{`${item.relacion.nombre}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText>{props.getError('programa', props.errores)}</FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <InputLabel htmlFor="age-native-simple">Semestre que se encuentra cursando</InputLabel>
        <FormControl className={classes.formControl} fullWidth error={props.getError('semestreSeleccionado', props.errores).length > 0}>
          <Select
            name='semestre'
            value={props.semestreSeleccionado}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleOnchangeSemestre}
          >
            <MenuItem value="">
              <em style={{ color: "rgba(0, 0, 0, 0.54)" }}>{`Seleccione Semestre`}</em>
            </MenuItem>
            <MenuItem key={1} value={1}> I </MenuItem>
            <MenuItem key={2} value={2}> II </MenuItem>
            <MenuItem key={3} value={3}> III </MenuItem>
            <MenuItem key={4} value={4}> IV </MenuItem>
            <MenuItem key={5} value={5}> V </MenuItem>
            <MenuItem key={6} value={6}> VI </MenuItem>
            <MenuItem key={7} value={7}> VII </MenuItem>
            <MenuItem key={8} value={8}> VII </MenuItem>
            <MenuItem key={9} value={9}> IX </MenuItem>
            <MenuItem key={10} value={10}> X </MenuItem>
          </Select>
        </FormControl>
        <FormHelperText>{props.getError('semestreSeleccionado', props.errores)}</FormHelperText>

      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Correo electrónico"
          id='correo'
          error={props.getError('correo', props.errores).length > 0}
          helperText={props.getError('correo', props.errores)}
          value={props.correo}
          onChange={handleOnchange}
          name="correo" />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Celular"
          id='celular'
          error={props.getError('celular', props.errores).length > 0}
          helperText={props.getError('celular', props.errores)}
          value={props.celular}
          onChange={handleOnchange}
          name="celular" />
      </Grid>
      <Grid item xs={12}>
        <FormControl className='form-control'>
          <InputLabel>Autorizo</InputLabel>
          <Select
            name='autorizo'
            value={props.autorizo}
            onChange={handleOnchangeAutorizar}
          >
            <MenuItem key={1} value={1}> SI </MenuItem>
            <MenuItem key={2} value={2}> NO </MenuItem>
          </Select>

          <FormHelperText className={classes.texto}>
            En cumplimiento de la Ley 1581 de 2012 y sus decretos reglamentarios en calidad de titular
            (es) de la información de manera libre, expresa e informada,
            autorizo a CORPORACIÓN UNIVERSIDAD DE LA COSTA - CUC y/o a la persona natural o
            jurídica a quién este encargue, a recolectar, almacenar, utilizar, circular, suprimir
            y en general, a usar mis datos personales para el cumplimiento de las siguientes
            finalidades: (i) Gestión de PQR, (ii) publicidad y prospección comercial, (iii) (i)
            Enseñanza universitaria o superior. Declaro que he conocido la Política de tratamiento
            de datos personales publicada en www.cuc.edu.co
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>


  )
}



const Form2 = function (props) {

  const classes = useStyles();

  // Map de los datos para crear un array de horarios
  const horarios = (props.cursosFormacion || []).map(curso => ({
    grupo: curso.grupo,   
    horario: curso.horario
  }));

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    if (name == 'checked1') {
      props.setIsChecked1(checked)
      props.setIsChecked2(false)
    } else if (name == 'checked2') {
      props.setIsChecked2(checked)
      props.setIsChecked1(false)
      props.setSemilleroSelect('')
    } else {
      props.setIsChecked1(false)
      props.setIsChecked2(false)
    }
  }
  const handleChangeSemillero = (event) => {
    props.setSemilleroSelect(event.target.value);
    if (props.errores.length > 0) {
      props.setErrores(props.errores.filter(er => er.llave != "listSemilleros"));
      // Elimina error cuando se escribe en el campo
    }
  };
  const handleChangeHorario = (event) => {
    props.setHorarioSelect(event.target.value);
    if (props.errores.length > 0) {
      props.setErrores(props.errores.filter(er => er.llave != "listSemilleros"));
      // Elimina error cuando se escribe en el campo
    }
  };
  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={12} >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormLabel style={{ marginRight: '4%' }} component="legend">¿Estás vinculado a grupo de semillero de investigación e innovación?</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                name='checked1'
                edge="start"
                onChange={handleCheckboxChange}
                checked={props.isChecked1}
                color="primary"
              />
            }
            label="SI"
          />
          <FormControlLabel
            control={
              <Checkbox
                name='checked2'
                edge="start"
                onChange={handleCheckboxChange}
                checked={props.isChecked2}
                color="primary"
              />
            }
            label="NO"
          />
        </div>
        <FormHelperText style={{ color: 'red' }}>{props.getError('checkbox', props.errores)}</FormHelperText>
      </Grid>
      {(props.isChecked1 == true) ?
        <>
          <Grid item xs={12}>
            <InputLabel htmlFor="age-native-simple">Seleccione grupo de semillero al que pertenece</InputLabel>
            <FormControl className={classes.formControl} fullWidth error={props.isChecked2 == false && props.getError('listSemilleros', props.errores).length > 0}>
              <Select
                value={props.semilleroSelect}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleChangeSemillero}
              >
                <MenuItem value="">
                  <em style={{ color: "rgba(0, 0, 0, 0.54)" }}>{`${props.listSemilleros.length} Semilleros Activos`}</em>
                </MenuItem>
                {props.listSemilleros.map((item, index) => (
                  <MenuItem key={index} value={item.id}>{`${item.nombre}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.isChecked2 == false &&
              <FormHelperText>{props.getError('listSemilleros', props.errores)}</FormHelperText>}
          </Grid>
        </>
        : ''} */}
      <Grid item xs={12}>
        <InputLabel style={{ marginLeft: '5px' }}>Escoja el horario de clases remoto al cual va a asistir
          (tenga en cuenta que debe asistir mínimo al 80% de las clases y cumplir con los
          compromisos asignados para poder ser certificado).
          No se aceptan modificaciones después de la inscripción.</InputLabel>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup value={props.horarioSelect} onChange={handleChangeHorario} error={props.getError('horario', props.errores).length > 0}>
          {horarios.map((horario) => (
            <FormControlLabel
              key={horario.grupo}
              value={`${horario.grupo}: ${horario.horario}`}
              control={<Radio />}
              label={`${horario.grupo}: ${horario.horario}`}
            />
          ))}
        </RadioGroup>
        <FormHelperText style={{ color: 'red' }}>{props.getError('horario', props.errores)}</FormHelperText>
      </Grid>
    </Grid>


  )
}

const Requisitos = function (props) {
  // let { requisitos, submit, requisitoInicial, hv } = props_c;

  const transformarDataAdj = (data) => {
    let r = [];
    data.map(req => r.push({ nombre: req.nombre, codigo: req.id, archivo: req.archivo }));

    return r;
  };

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />;
    return <div> {cargar}</div>;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => props.submit(archivos)}
                limite={1}
                mostrarMensaje={false}
                enLista={true}
                lista={transformarDataAdj(props.requisito)}
                formatos={true}
                mostrarRender={true}
                mostrar={true}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
