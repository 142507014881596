import React, { Component, useEffect } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, Fab, Box, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { api, coloresEmma } from '../../global/js/funciones';
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { consulta } from '../../global/js/funciones';
import emma_w from "../../global/imagenes/emma_w.png";
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from '../general/BotonesAccion'
import TareasTerminadas from "../general/TareasTerminadas";

import emma_s1 from '../../global/imagenes/emma_s1.png';
import imagen from '../../global/imagenes/personas/users.png';


const styles = StyleSheet.create({
  body: {
    margin: 28,
    flexGrow: 1,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row'
  },
  titulo_nombre: {
    color: coloresEmma.secondarycolor,
    fontSize: '20px',
    textAlign: 'center'
  },
  titulos: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FFF',
    padding: '2px',
    textAlign: 'center',
    margin: '10px 0px',
    fontSize: '11px'
  },
  Tprincipal: {
    marginTop: '5px'
  },
  Tprincipal2: {
    color: 'gray',
    fontSize: '11px',
    textAlign: 'right',
    paddingTop: '12px'
  },
  Tsecundario: {
    fontSize: '12px',
    color: coloresEmma.secondarycolor,
    marginTop: '5px'
  },
  Ttexto: {
    fontSize: '11px',
    marginTop: '5px',
    textAlign: 'justify'
  },
  TextosInfo: {
    fontSize: '11px',
    marginTop: '3px',
    textAlign: 'center'
  },
  THabilidades: {
    marginTop: '5px',
    fontSize: '13px',
    paddingLeft: '5px'
  },
  bloque: {
    marginTop: '15px'
  },
  nivelesSi: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    borderRadius: '2px',
    padding: '2px'
  },
  nivelesNo: {
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    border: '2px solid #F5EBB6',
    borderRadius: '2px'
  },
  foto: {
    display: 'grid',
    justify: 'center',
    self: 'center',
    width: '100px',
    height: '100px',
    border: '50px',
    padding: '2px',
    borderRight: `50px solid ${coloresEmma.secondarycolor}`,
    borderLeft: `50px solid ${coloresEmma.secondarycolor}`,
    borderTop: `50px solid ${coloresEmma.secondarycolor}`,
    borderBottom: `50px solid ${coloresEmma.secondarycolor}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px'
  }
});


function generarPDF(personal, habilidad, experiencia, academico, distincion, fotoPerfil) {
  let personales = habilidad.filter(i => i.tipo === "personal")
  let laboral = habilidad.filter(i => i.tipo === "laboral")

  const niveles = (nivel) => {
    switch (nivel) {
      case "5":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>5</Text></View>
          </View>
        )
      case "4":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "3":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "2":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "1":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
    }
  }

  return (
    <Document>
      <Page size="A4">
        <View style={styles.body}>
          <View style={styles.row}>
            <View style={{ width: '40%', paddingRight: '15px' }}>
              <Image style={styles.foto} src={fotoPerfil} alt="images" />

              <Text style={[styles.TextosInfo, { fontWeight: 'bold' }]}>{`${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""} ${personal[0].persona ? personal[0].persona.segundo_apellido : ""}`}</Text>
              <Text style={styles.TextosInfo}>{personal[0].persona ? personal[0].persona.telefono : ""}</Text>
              <Text style={styles.TextosInfo}>{personal[0] ? personal[0].email : ""}</Text>
              <Text style={[styles.TextosInfo, { color: coloresEmma.secondarycolor }]}>{`Linkedin de ${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""}`}</Text>

              <Text style={styles.titulos}>SOBRE MI</Text>
              <Text style={styles.Ttexto}>{personal[0] ? personal[0].perfil : ""}</Text>

              <Text style={styles.titulos}>HABILIDADES</Text>
              <Text style={styles.Tprincipal}>LABORALES</Text>
              {laboral.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}</View>
                </View>
              ))}
              <Text style={styles.Tprincipal}>PERSONALES</Text>
              {personales.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}
                  </View>
                </View>
              ))}
            </View>
            <View style={{ width: '60%', paddingLeft: '15px' }}>
              <Text style={styles.titulo_nombre}>{`${personal[0].persona ? personal[0].persona.primer_nombre : ""} ${personal[0].persona ? personal[0].persona.primer_apellido : ""} ${personal[0].persona ? personal[0].persona.segundo_apellido : ""}`}</Text>

              <Text style={styles.titulos}>EXPERIENCIA</Text>
              {
                experiencia.map(e => (
                  e.empresa ?
                    <View style={styles.bloque}>
                      <View style={styles.row}>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal}>{e.cargo}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal2}>{`(${e.fecha_inicio_exp.substring(0, 4)} - ${e.en_curso_lab == '1' ? 'Presente' : e.fecha_final_exp.substring(0, 4)})`}</Text>
                        </View>
                      </View>
                      <Text style={styles.Tsecundario}>{e.empresa}</Text>
                      <Text style={styles.Ttexto}>{e.responsabilidades}</Text>
                    </View>
                    :
                    <View style={styles.bloque}>
                      <Text style={styles.Tprincipal}>No cuento con experiencia laboral</Text>
                    </View>
                ))
              }

              <Text style={styles.titulos}>EDUCACIÓN</Text>
              {
                academico.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.profesion ? `${e.profesion.toUpperCase()} - ${e.titulo}` : ''}</Text>
                    <Text style={styles.Tsecundario}>{e.universidad}</Text>
                    <Text style={styles.Ttexto}>{`(${e.fecha_inicio_est.substring(0, 4)} - ${e.en_curso_aca == '1' ? 'Presente' : e.fecha_final_est.substring(0, 4)})`}</Text>
                  </View>
                ))
              }

              <Text style={styles.titulos}>DISTINCIONES Y LOGROS</Text>
              {
                distincion.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.descripcion.toUpperCase()}</Text>
                  </View>
                ))
              }
            </View>
          </View>
        </View>
      </Page>
    </Document>

  )

}

// function BtnGenerarImg({ obtenerImagenDesdeUrl, idDetallePostulante, setearData }) {

//   useEffect(() => {
//     consulta("api/v1.0/portal/obtener_detalle_postulante", { idDetallePostulante }, "post", (error, estado, resp) => {
//       if (estado === 200) setearData(resp);
//     })

//   }, [idDetallePostulante]);


//   const handleClick = async () => {
//     await obtenerImagenDesdeUrl();
//   };

//   return (
//     <Box style={{ display: 'flex', justifyContent: 'space-evenly', 'marginLeft': 'auto' }}>
//       <Tooltip title='Descargar hoja de vida' aria-label="add">
//         <IconButton
//           onClick={handleClick}
//           style={{ color: '#2196f3' }}
//           aria-label="delete">
//           <GetAppIcon fontSize="small" />
//         </IconButton>
//       </Tooltip>
//     </Box>
//   );
// }

function BtnDescargar({ personal, habilidad, experiencia, academico, distincion, base64Image, reset }) {

  useEffect(() => {
    const generarYDescargarPDF = async () => {
      if (base64Image) {
        console.log("Generando PDF...");
        const doc = generarPDF(personal, habilidad, experiencia, academico, distincion, base64Image);
        try {
          const pdfBlob = await pdf(doc).toBlob();
          const url = URL.createObjectURL(pdfBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'Hoja_de_vida.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          console.log("PDF descargado exitosamente");
        } catch (error) {
          console.error("Error al generar el PDF:", error);
        } finally {
          reset();
        }
      }
    };
    generarYDescargarPDF();
  }, [personal, habilidad, experiencia, academico, distincion, base64Image, reset]);
  return null;
}


class DescargarHojaDeVida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      detallePostulante: null,
      base64Image: "",
      imagenLista: false,
    };
  }

  obtenerDetalle = async (id) => {
    this.setState({ cargando: true });
    return new Promise((resolve, reject) => {
      consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
        if (error) {
          this.setState({ cargando: false });
          reject(error);
        } else if (estado === 200) {
          resolve(resp);
        } else {
          this.setState({ cargando: false });
          reject(new Error("Error en la respuesta del servidor"));
        }
      });
    });
  }

  obtenerImagenDesdeUrl = async (personal, intentos = 5, delay = 2000) => {
    const archivoUrl = api + personal[0].archivo;
    const proxyUrl = 'https://api.allorigins.win/get?url=';
    const targetUrl = encodeURIComponent(archivoUrl);
    const finalUrl = proxyUrl + targetUrl;
    for (let intento = 1; intento <= intentos; intento++) {
      try {
        const response = await fetch(finalUrl);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const data = await response.json();
        return data.contents;
      } catch (error) {
        console.error(`Intento ${intento} fallido: `, error);
        if (intento < intentos) {
          await new Promise(res => setTimeout(res, delay));
        } else {
          console.error("Error al obtener la imagen después de varios intentos.");
          throw error;
        }
      }
    }
  };


  manejarDescarga = async () => {
    this.setState({ cargando: true });
    const { idDetallePostulante } = this.props;
    try {
      const detallePostulante = await this.obtenerDetalle(idDetallePostulante);
      const { personal } = detallePostulante;
      let base64Image = "";
      if (personal[0].archivo === null) base64Image = imagen
      else base64Image = await this.obtenerImagenDesdeUrl(personal)
      this.setState({ detallePostulante, base64Image, imagenLista: true, cargando: false });
    } catch (error) {
      console.error("Error durante la descarga:", error);
      this.setState({ cargando: false });
    }
  }

  resetearEstado = () => {
    this.setState({ base64Image: "", detallePostulante: null, imagenLista: false, cargando: false });
  }

  render() {
    const { cargando, imagenLista, detallePostulante, base64Image } = this.state;
    const { personal, habilidad, experiencia, academico, distincion } = detallePostulante || {};
    return (
      <>
        {
          cargando ? (
            <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 'auto' }}>
              <Tooltip title='Descargar hoja de vida' aria-label="add">
                <IconButton
                  style={{ color: '#2196f3' }}
                  disabled={cargando}
                >
                  <CircularProgress size={24} />
                </IconButton>
              </Tooltip>
            </Box>
          )
            :
            <>
              {!cargando && !imagenLista && (
                <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 'auto' }}>
                  <Tooltip title='Descargar hoja de vida' aria-label="add">
                    <IconButton
                      onClick={this.manejarDescarga}
                      style={{ color: '#2196f3' }}
                    >
                      <GetAppIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {imagenLista && (
                <BtnDescargar
                  personal={personal}
                  habilidad={habilidad}
                  experiencia={experiencia}
                  academico={academico}
                  distincion={distincion}
                  base64Image={base64Image}
                  reset={this.resetearEstado}
                />
              )}
            </>
        }
      </>
    );
  }
}

export default DescargarHojaDeVida;
