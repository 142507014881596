// Local

//export const REQUISITO_CURSOS=4125;
//export const TIPO_IDENTIFICACION=2;
//export const ESTADOS_CURSOS=4126;
//export const GRUPOS_CURSOS=4129
// Producción

export const REQUISITO_CURSOS=253;
export const TIPO_IDENTIFICACION=2;
export const ESTADOS_CURSOS=252;
export const GRUPOS_CURSOS=300


// export const DIPLOMADOS = 30;
// export const POSGRADOS = 29;
// export const CURSOS_LIBRES = 31;
// export const ESTADOS_ID = 33;
// export const PROCESOS = 34;
// export const MATERIAS = 32;
// export const PROGRAMAS = 5;
// export const CURSOS_LIBRES_PRE = 46;
// export const NOTAS = 47;
// export const SOLICITUDES_INVESTIGACION = 194;
// export const ESTADO_INVESTIGACION = 166;
// export const SOLICITUDES_PAR_INVES = 167;
// export const PAISES = 195;
// export const IDIOMAS = 169;
// local
// export const DIPLOMADOS = 30;
// export const POSGRADOS = 29;
// export const CURSOS_LIBRES = 31;
// export const ESTADOS_ID = 33;
// export const PROCESOS = 34;
// export const MATERIAS = 32;
// export const PROGRAMAS = 5;
// export const CURSOS_LIBRES_PRE = 46;
// export const NOTAS = 47;
// export const SEMI_DEPARTAMENTOS = 117;
// export const SEMI_PROGRAMAS = 5;
// export const SEMI_LINEA = 119;
// export const SEMI_SUBLINEA = 120;
// export const SEMI_GRUPOS_INVES = 121;
// export const SEMI_AREAS_INVES = 122;
// export const SEMI_ROLES = 123;
// export const SOLICITUDES_INVESTIGACION = 153;
// export const ESTADO_INVESTIGACION = 125;
// export const PAISES = 154;
// export const IDIOMAS = 128;