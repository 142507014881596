import React from 'react'
import AppBarModal from '../general/AppBarModal'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { actualizarMensaje } from '../../redux/actions/actGlobal';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Grid, Link, Paper, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import assigment from '../../global/imagenes/assigment.png';
import * as Funciones from '../../global/js/funciones';
import TareasTerminadas from '../general/AppBarModal';
import emma_w from '../../global/imagenes/emma_w.png';
import LinearProgress from '@material-ui/core/LinearProgress';
import CursoStepperEstu from './Cursos/CursoStepperEstu';
import { BtnForm } from '../general/BotonesAccion';
import FormCursos from './Cursos/FormCursos';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
	color: {
		transition: 'all 0.3s',
		'&:hover': {
			transform: "scale(1.05)",
		},
	},
	center: {
		textAlign: 'center'
	},
	alert: {
		alignItems: 'center', 
		justifyContent: 'center'
	},
	texto: {
		textAlign: 'justify'
	},
	margin: {
		marginTop: '40px',
	}
});

const SemilleroSolicitudesEst = (props) => {

	const classes = useStyles()
	//Estado open para abrir y cerrar el modal.
	const [open, setOpen] = React.useState(false);
	//Estado para cambiar de step.
	const [activeStep, setActiveStep] = React.useState(0);
	//Estados necesarios para guardar la informacion del estudiante
	const [primernombre, setPrimernombre] = React.useState("");
	const [segundonombre, setSegundonombre] = React.useState("");
	const [primerapellido, setPrimerapellido] = React.useState("");
	const [segundoapellido, setSegundoapellido] = React.useState("");
	const [identificacion, setIdentificacion] = React.useState("");
	const [tiposidentificacion, setTiposidentificacion] = React.useState([]);
	const [cursosFormacion,  setCursosFormacion] = React.useState([]);
	const [tipoidentificacion, setTipoidentificacion] = React.useState("");
	const [celular, setCelular] = React.useState("");
	const [telefono, setTelefono] = React.useState("");
	const [correo, setCorreo] = React.useState("");
	const [programa, setPrograma] = React.useState([]);
	const [programaSelect, setProgramaSelect] = React.useState("");
	const [isChecked1, setIsChecked1] = React.useState(false);
	const [isChecked2, setIsChecked2] = React.useState(false);
	const [requisito, setRequisito] = React.useState([]);
	const [horarioSelect, setHorarioSelect] = React.useState("");
	const [semestreSeleccionado, setSemestreSeleccionado] = React.useState("");
	const [checked, setChecked] = React.useState([]);
	//Variable que me ayuda con el checkbox
	const [helperCheck, setHelperCheck] = React.useState([]);
	//Lista de Semilleros
	const [listSemilleros, setListSemilleros] = React.useState([]);
	const [semilleroSelect, setSemilleroSelect] = React.useState("");
	//Errores
	const [errores, setErrores] = React.useState([]);
	//Cargando
	const [cargando, setCargando] = React.useState(false)
	const [cargandoFuera, setCargandoFuera] = React.useState(false)
	const [solicitudesUsuario, setSolicitudesUsuario] = React.useState([])
	const [autorizo, setAutorizo] = React.useState('')
	const [fechainscripcion, setFechainscripcion] = React.useState('')
	const [fechainiciocurso, setFechainiciocurso] = React.useState('')
	const [linkinformacion, setLinkinformacion] = React.useState('')
	//Data final
	let data = {}
	//Variable que obtiene todos los titulos del step
	const steps = getSteps();
	//Dispatch para utilizar los mensajes emergentes
	const dispatch = useDispatch();

	// habilitrar/deshabilitar
	const [valido, setValido] = React.useState(true);

	const isInitialMount = React.useRef(true);


	React.useEffect(() => {
		//Obtengo los prceosos activos de la persona activa.
		if (isInitialMount.current) {
			isInitialMount.current = false
			const obtenerProcesos = async () => {
				let solicitud = await obtenerSolicitud();
				setSolicitudesUsuario(solicitud)
				// const existe = solicitud.some(solicitud => solicitud.estado_actual.codigo !== "");
				// console.log(existe)
				// if(solicitud.length > 0){
				//     console.log(solicitud)
				//     setSolicitudActiva(true)
				// } 
				setCargando(false)
			}
			const fetchData = async () => {
				let status = await Funciones.obtenerValoresFiltros([
					{ llave: "codigo", valor: "Status_niveles_formacion" },
				  ]);
				  if (status) {
					if (status[0].valora === "activo"){
						setValido(true);
						setFechainscripcion(status[0].valorb)
						setFechainiciocurso(status[0].valorc)
						setLinkinformacion(status[0].archivo)
					}else{
						setValido(false);
						setFechainscripcion(status[0].valorb)
						setFechainiciocurso(status[0].valorc)
						setCargando(false);
					} 
				  }
			}
			obtenerProcesos()
			fetchData()
		}
	})

	const obtenerSolicitud = async () => {
		let f = await Funciones.generarFiltros([{ 'llave': 'usuario_registro', 'valor': props.usuario.id }, {'llave': 'estado', 'valor': '1'}]);
		return new Promise(resolve => {
			Funciones.consulta(`api/v1.0/cursoformacion/solicitud?${f}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			})
		})
	}

	//Funciones para que abra el modal.
	const handleClickOpen = () => {
		// const existe = solicitudesUsuario.some(solicitud => solicitud.estado_actual.codigo !== "");
		// if (existe) setSolicitudActiva(true)
		 setOpen(true);
	};
	//Funcion para el cierre del modal.
	const handleClose = () => {
		setOpen(false);
		setPrimernombre("");
		setSegundonombre("")
		setPrimerapellido("")
		setSegundoapellido("")
		setIdentificacion("")
		setTiposidentificacion([])
		setTipoidentificacion("")
		setIsChecked1("")
		setIsChecked2("")
		setRequisito("")
		setCelular("");
		setTelefono("");
		setCorreo("");
		setSemilleroSelect("")
		setPrograma([]);
		setProgramaSelect("")
		setChecked([])
		setActiveStep(0)
		data = {};
	};
	const handleClose1 = () => {
		setOpen(false);
		
	};

	const getError = (llave, errores) => {
		let existe = errores.find(e => e.llave === llave);
		return existe ? existe.mensaje : '';
	}

	const validarCorreo = (valor) => {
		let valido = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
			valor
		);

		return valido;
	};

	const validateInputs = (campos = []) => {
		return new Promise((resolve) => {
			let errores = [];
			let date_now = new Date()

			campos.map(({ value, name, err, value2 }) => {
				if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
				if (err.empty == false && value == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
				if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
				/*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
				if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
				if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
				if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
				if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
				if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
				if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
			});

			resolve(errores);
		});
	}

	//Funcion para hacer un siguiente en el Step
	const handleNext = async () => {
		if (activeStep == 0) {
			setErrores(await validateInputs([
				{ value: tipoidentificacion, name: 'tipoidentificacion', err: { empty: false } },
				{ value: identificacion, name: 'identificacion', err: { empty: false } },
				{ value: programaSelect, name: 'programa', err: { empty: false } },
				{ value: semestreSeleccionado, name: 'semestreSeleccionado', err: { empty: false } },
				{ value: correo, name: 'correo', err: { empty: false } },
				{ value: celular, name: 'celular', err: { empty: false } },
			]))
			if (programaSelect == "") {
			} else {
				data = {
					celular: celular,
					telefono: telefono,
					correo: correo,
					programa: programaSelect,
				}
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		}
		if (activeStep == 1) {
			const validateString = (variable) => {
				if (typeof variable == 'number') {
					return variable.toString()
				} else {
					return variable
				}
			}

			let validationInputs = [
				{ value: horarioSelect, name: 'horario', err: { empty: false } },
			];
			if (horarioSelect == "") {
				setErrores(await validateInputs (validationInputs))
			} else {
				data = {
					horario: horarioSelect,
				}
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
			
			// if (isChecked1) {
			// 	validationInputs.push({ value: semilleroSelect, name: 'listSemilleros', err: { empty: false } });
			// }

			// if (!isChecked2 && !isChecked1) {
			// 	validationInputs.push({ value: isChecked1, name: 'checkbox', err: { empty: false } });
			// 	setErrores(await validateInputs(validationInputs));
			// } else if (isChecked1 && semilleroSelect == '') {
			// 	setErrores(await validateInputs(validationInputs));
			// } else if (isChecked2 && horarioSelect === '') {
			// 	setErrores(await validateInputs(validationInputs));
			// }
			// if (isChecked2 && horarioSelect === '') {
			// 	setErrores(await validateInputs(validationInputs));
			// }
			// else {

			// 	data = {
			// 		horario: 'hola'
			// 	}

			// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
			// }
		}
	}

	const submit = async (archivo) => {
		if (archivo.length == 0) {
			let titulo = "Debe Adjuntar Un Soporte."
			let tipo = "info"
			dispatch(
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
			);
		} else {
			console.log('archivo')
			console.log(archivo)
			data = await Funciones.crear_form_data({
				programa: programaSelect,
				semestre: semestreSeleccionado,
				semillero: '',
				horario: horarioSelect,
				programa: programaSelect,
				// celular:celular,
				// telefono:telefono,
				// correo:correo,
			})
			data.append(`archivo`, archivo[0].archivo)
			data.append(`codigo`, archivo[0].codigo)
			SendDataToApi(data)
		}

	}

	const SendDataToApi = async (data) => {

		console.log(data)
		setCargandoFuera(true)


		let url = "api/v1.0/cursoformacion/crearCursosFormacion"
		const callback = (error, estado, resp) => {
			let titulo = "Ha ocurrido un error, contacte con el administrador."
			let tipo = "info"

			if (!error) {
				if (estado === 201) {
					tipo = "success"
					titulo = resp.titulo
					handleClose();
				} else {
					tipo = "info"
					titulo = resp.titulo
				}
			}

			dispatch(
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
			);
			setCargandoFuera(false)
		}
		const msg = await Funciones.formulario(url, data, 'POST', callback)
		// }
	}

	//Funcion para hacer un back en el Step
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	//Funcion para hacer un reset en el Step
	const handleReset = () => {
		setActiveStep(0);
	};
	const SolicitudTerminadaModal = ({ open, onClose }) => {
		return (
		  <Dialog open={open} onClose={handleClose1} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true} >
			<AppBarModal titulo='Formulario Seminario de Investigación Adjunto' mostrarModal={() => { handleClose1() }} titulo_accion="" accion="" />
			<DialogContent>
			  <Alert severity="info" className={classes.alert} >
			  <Typography className={classes.center}  variant="h6">Solicitud Cerrada</Typography>
				Plazo máximo de inscripción: {fechainscripcion} <br />
				Fecha de comienzo del curso: {fechainiciocurso} 
			  </Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose1} >
				Cerrar
				</Button>
            </DialogActions>
		  </Dialog>
		);
	  };

	const pintarCard = () => {
		return (
			<Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 30 }}>
				<Grid item xs={10} sm={8} md={6} lg={4} style={{ padding: '0% 2% 0% 2%' }} onClick={() => { handleClickOpen() }}>
					<Paper elevation={8} square>
						<Card className={classes.color} style={{ maxWidth: "100%", height: '10%', cursor: 'pointer' }} elevation={1}>
							<CardHeader
								avatar={<Avatar aria-label="recipe" style={{ backgroundColor: '#00F087' }}>S</Avatar>}
								title={`SEMINARIO DE INVESTIGACIÓN ADJUNTO`}
							/>
							<CardContent >
								<Grid container direction={'row'} style={{ margin: '-20px 0px -10px 0px' }} >
									<Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "130px" }}>
										<img src={assigment} style={{ width: '110px', maxWidth: '200px', minWidth: '100px', height: "110px" }} alt='Imagen Tipo' />
									</Grid>
								</Grid>
								<Grid container direction={'row'}>
									<Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px" }}>
										<Typography className={classes.texto} variant="body2" color="textSecondary" component="p" >
											Sesiones remotas a través de Teams.
											El curso se compone de 7 módulos: Generalidades de la Investigación en Unicosta, Los enfoques cuantitativo y cualitativo de la investigación, Búsquedas en bases de datos especializadas, Gestión de la comunicación, Habilidades de lectoescritura, Redacción científica y Normatividad. <br /> ¡Realiza este curso para poder ingresar a un grupo de semillero de investigación y categorizarte como semillero adjunto!
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Paper>
				</Grid>
			</Grid>)
	}

	//Retorna el modal de los formularios de los semilleros.
	return (
		<div>
			{pintarCard()}

			{
				open && valido ? (
					 <FormCursos
					 	open={open}
					 	steps={steps}
					 	style_classes={classes}
					 	activeStep={activeStep}
					 	cargandoFuera={cargandoFuera}
					 	handleClose={handleClose}
					 	handleNext={handleNext}
					 	handleBack={handleBack}

					// 	//input
					 	primernombre={primernombre}
					 	segundonombre={segundonombre}
					 	primerapellido={primerapellido}
					 	segundoapellido={segundoapellido}
					 	identificacion={identificacion}
						cursosFormacion={cursosFormacion}
					 	tiposidentificacion={tiposidentificacion}
					 	tipoidentificacion={tipoidentificacion}
					 	celular={celular}
					 	telefono={telefono}
					 	correo={correo}
					 	programa={programa}
					 	isChecked1={isChecked1}
					 	isChecked2={isChecked2}
					 	requisito={requisito}
					 	horarioSelect={horarioSelect}
					 	semestreSeleccionado={semestreSeleccionado}
					 	autorizo={autorizo}
						fechainscripcion={fechainscripcion}
						fechainiciocurso={fechainiciocurso}
						linkinformacion={linkinformacion}

					// 	// set
					 	setPrimernombre={setPrimernombre}
					 	setSegundonombre={setSegundonombre}
					 	setPrimerapellido={setPrimerapellido}
					 	setSegundoapellido={setSegundoapellido}
					 	setIdentificacion={setIdentificacion}
					 	setTiposidentificacion={setTiposidentificacion}
						setCursosFormacion={ setCursosFormacion}
					 	setTipoidentificacion={setTipoidentificacion}
					 	setCelular={setCelular}
					 	setTelefono={setTelefono}
					 	setCorreo={setCorreo}
					 	setPrograma={setPrograma}
					 	setIsChecked1={setIsChecked1}
					 	setIsChecked2={setIsChecked2}
					 	setRequisito={setRequisito}
					 	setHorarioSelect={setHorarioSelect}
					 	setSemestreSeleccionado={setSemestreSeleccionado}
					 	setAutorizo={setAutorizo}
						setFechainscripcion={setFechainscripcion}
						setFechainiciocurso={setFechainiciocurso}
						setLinkinformacion={setLinkinformacion}

					 	listSemilleros={listSemilleros}
					 	setListSemilleros={setListSemilleros}

					 	checked={checked}
					 	setChecked={setChecked}

					 	helperCheck={helperCheck}
					 	setHelperCheck={setHelperCheck}
					 	// Errores
					 	getError={getError}
					 	errores={errores}
					 	setErrores={setErrores}

					 	validateInputs={validateInputs}
					// 	//Cargando
					 	setCargando={setCargando}
					 	cargando={cargando}


					 	programaSelect={programaSelect}
					 	setProgramaSelect={setProgramaSelect}

					 	semilleroSelect={semilleroSelect}
					 	setSemilleroSelect={setSemilleroSelect}

					 	cargandoModal={setCargandoFuera}
					 	submit={submit}
					 />
				):(
					<SolicitudTerminadaModal
					open={open}
					setOpen={setOpen}
					/>
				)
			}



		</div>
	)
}

//Nombre de las opciones de arriba del Stepper
function getSteps() {
	return ['Mis Datos', 'Horarios', 'Requisito'];
}

//Exporto el componente funcional. Para que pueda ser llamado
export default SemilleroSolicitudesEst 