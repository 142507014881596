//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'
//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es el stepper.
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';

//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
//Iconos:

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//Añadir
import AddIcon from '@material-ui/icons/Add';
//Editar
import EditIcon from '@material-ui/icons/Edit';
//Eliminar
import DeleteIcon from '@material-ui/icons/Delete';


//AppBarModal header de los modales
// import SemillerosAddObj from '../Hijos/SemillerosAddObj'
// import SemillerosAddDepPro from '../Hijos/SemillerosAddDepPro'
// import SemillerosAddLinea from '../Hijos/SemillerosAddLinea'

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputFile from "../../general/InputFile";
import { createMuiTheme } from '@material-ui/core/styles';
//MenuItem
import MenuItem from '@material-ui/core/MenuItem';

// Menu Icon
import MenuBookIcon from '@material-ui/icons/MenuBook';
//Importo las funciones para hacer peticiones
import * as Funciones from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
//Constantes del Helper
import * as Helper from './Helper';

//Listar datos para el Form3
import ListarDatos from '../../general/ListarDatos';
//Importo el modal para agregar responsables
// import SemillerosAddRes from '../Hijos/SemillerosAddRes';
// import { BtnEliminar } from "../../general/BotonesAccion";

import FormHelperText from '@material-ui/core/FormHelperText';

import SemillerosListSemi from '../Hijos/SemillerosListSemi';
import SemillerosEditObj from '../Hijos/SemillerosEditObj';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { coloresEmma } from '../../../global/js/funciones';
//Estilos para el Stepper necesarios para funcionar.
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    colorWhite : {
        color: '#fff'
    },
    flexGrow: {
        flexGrow: 1,
    },
    flexCenter:{
        textAlign: "center",
        marginTop: "5px"
    },
    formControl: {
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    colorEmmma: {
      color: coloresEmma.secondarycolor,
      transition: 'all 0.3s',
      '&:hover': {
        color: "#fff",
        backgroundColor: coloresEmma.secondarycolor,
        transform: "scale(0.8)",
      },
    },
    colorDelete: {
      color: "#d11a2a",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#d11a2a",
        transform: "scale(1.3)",
      },
    },
    colorEdit: {
      color: "#0074C5",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#0074C5",
        transform: "scale(1.3)",
      },
    }
}));

//Codigo del Stepper del MaterialUI
export default function SemilleroStepperEstu(props) {

    const classes = useStyles();

    const [cargandoF, setCargandoF] = React.useState(true);
    
    //useEffect para consumir las apis cuando cargue el componente.
    React.useEffect( () => {
        ObtenerDatosIniciales()
        
    }, [])

  const ObtenerDatosIniciales = async () => {
    setCargandoF(true)
    await obtenerInfoUser()
    await obtenerSemilleros()
    await getAllSemilleros()
    setCargandoF(false)
  }


    //Obtengo los departamentos con la funcion obtenerValores de funciones.js
    const obtenerInfoUser = async () => {
        try{
            const data = await Funciones.obtenerMisDatos()
            if(data.persona.celular == null){
                props.setCelular("")
            }else{
                props.setCelular(data.persona.celular)
            }
            
            if(data.persona.telefono == null){
                props.setTelefono("")
            }else{
                props.setTelefono(data.persona.telefono)
            }
            
            if(data.persona.correo_personal == null){
                props.setCorreo("")
            }else{
                props.setCorreo(data.persona.correo_personal)
            }

            if(data.programas.length != 0){
                props.setPrograma(data.programas)
            }

        }catch(err){
            console.log(err)
        } 
    }

    const obtenerSemilleros = async () =>{
      try{
        const data = await obtenerSemillerosSolicitudes()
        props.setListSemilleros(data)

        props.setCargando(false)
      }catch(e){
        console.log(e)
      }
    }

    const obtenerSemillerosSolicitudes = async () => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/ObtenerSemillero2?solicitud__estado_actual__codigo=Semi_activo`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
    }

    const getAllSemilleros = async (f) => {
      return new Promise(resolve => {
          Funciones.consulta(`api/v1.0/investigacion/listaSolicitudes/1?${f}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          })
      })
  }


    if(props.cargando == true){
      return(
        <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
      )
    }else{
      return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {!cargandoF ?
                  getStepContent(props.hola, props) :
                  <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
                }
              </div>
            )}
          </div>
        </div>
      );
    }
}

//Aqui es donde se retorna el contenido de esa opcion del Stepper, osea los formularios.
function getStepContent(stepIndex, props) {

    switch (stepIndex) {
      case 0:
        return (
            <Form1 
            hola={props.activeStep}
            steps={props.steps}
            handleReset={props.handleReset}
            //TextFields
            celular={props.celular}
            telefono={props.telefono}
            correo={props.correo}
            programa={props.programa}

            setCelular={props.setCelular}
            setTelefono={props.setTelefono}
            setCorreo={props.setCorreo}
            setPrograma={props.setPrograma}

            cvlac={props.cvlac}
            setCvlac={props.setCvlac}
            scholar={props.scholar}
            setScholar={props.setScholar}
            researchGate={props.researchGate}
            setResearchGate={props.setResearchGate}
            academiaEdu={props.academiaEdu}
            setAcademiaEdu={props.setAcademiaEdu}

            semilleroAdjunto={props.semilleroAdjunto}
            setSemilleroAdjunto={props.setSemilleroAdjunto}

            metodologiaInves={props.metodologiaInves}
            setMetodoliaInves={props.setMetodoliaInves}

            experiencia={props.experiencia}
            setExperiencia={props.setExperiencia}
            //Errores
            getError={props.getError}
            errores={props.errores}
            setErrores={props.setErrores}

            validateInputs={props.validateInputs}
            //Cargando
            cargando={props.cargando}
            setCargando={props.setCargando}

            programaSelect={props.programaSelect}
            setProgramaSelect={props.setProgramaSelect}

          adjMetodologia={props.adjMetodologia}
          setAdjMetodologia={props.setAdjMetodologia}

          adjSeminario={props.adjSeminario}
          setAdjSeminario={props.setAdjSeminario}

            />
        );
      case 1:
        return (
            <ListarSemilleros 
              listSemilleros={props.listSemilleros}
              setListSemilleros={props.setListSemilleros}

              checked={props.checked}
              setChecked={props.setChecked}

              helperCheck={props.helperCheck}
              setHelperCheck={props.setHelperCheck}
            />
        );
      default:
        return 'Unknown stepIndex';
    }
}

const Form1 = function (props) {

    const classes = useStyles();
    const fileInput = React.createRef();
    
    const handleChangeAdjunto = (event) => {
      props.setSemilleroAdjunto(event.target.checked);
      props.setAdjSeminario('');
    };

    const handleChangeMetodologia = (event) => {
      props.setMetodoliaInves(event.target.checked);
      props.setAdjMetodologia('');
    };
    
    //Funcion para asginar Departamentos y Programas al select.
    const handleChangeDepaPro = (event) => {
      props.setProgramaSelect(event.target.value);
      if(props.errores.length > 0){
        props.setErrores(props.errores.filter(er => er.llave != "programa"));
        // Elimina error cuando se escribe en el campo
      }
    };

    //Metodos OnChange para la escritura de los inputs.
    const handleOnchange = (e) =>{
      if(e.target.name == "celular"){
        props.setCelular(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "celular"));
          // Elimina error cuando se escribe en el campo
        }
      }else if(e.target.name == "telefono"){
        props.setTelefono(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "telefono"));
        }
      }else if(e.target.name == "correo"){
        props.setCorreo(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "correo"));
        }
      }else if(e.target.name == "cvlac"){
        props.setCvlac(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "cvlac"));
          // Elimina error cuando se escribe en el campo
        }
      }else if(e.target.name == "scholar"){
        props.setScholar(e.target.value)
      }else if(e.target.name == "experiencia"){
        props.setExperiencia(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "experiencia"));
        }
      }else if(e.target.name == "adj_seminario"){
        props.setAdjSeminario(e.target.value)
        if(props.errores.length > 0){
          props.setErrores(props.errores.filter(er => er.llave != "adj_metodologia"));
          // Elimina error cuando se escribe en el campo
        }
      }else if(e.target.name == "adj_metodologia"){
        props.setAdjMetodologia(e.target.value)
      }

    };
    return (
        <div className={classes.flexGrow}>
        <Grid container spacing={3} style={{'marginBottom': '20px'}}>
          <Grid item xs={12} className='oculto'>
            <TextField
              ref={fileInput}
              required
              type="file"
              id="adj_seminario"
              name="adj_seminario"
              label="Adjuntar Identificación"
              fullWidth
              autoComplete="billing address-line1"
              onChange={handleOnchange}
            />
            <TextField
              ref={fileInput}
              required
              type="file"
              id="adj_metodologia"
              name="adj_metodologia"
              label="Adjuntar Diploma"
              fullWidth
              autoComplete="billing address-line1"
              onChange={handleOnchange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
            fullWidth 
            label="Celular" 
            id = 'celular'
            error = { props.getError('celular', props.errores).length > 0}
            helperText = { props.getError('celular', props.errores) }
            value={props.celular} 
            onChange={handleOnchange}
            name="celular"/>
          </Grid>
          <Grid item xs={6}>
            <TextField 
            fullWidth 
            label="Telefono"
            error = { props.getError('telefono', props.errores).length > 0}
            helperText = { props.getError('telefono', props.errores) }
            value={props.telefono} 
            onChange={handleOnchange}
            name="telefono"/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Correo Personal"
              error = { props.getError('correo', props.errores).length > 0}
              helperText = { props.getError('correo', props.errores) }
              fullWidth
              value={props.correo} 
              onChange={handleOnchange}
              name="correo"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="age-native-simple">Programa</InputLabel>
            <FormControl className={classes.formControl} fullWidth error = { props.getError('programa', props.errores).length > 0}>
              <Select
                 value={props.programaSelect}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={handleChangeDepaPro}
              >
                <MenuItem value="">
                    <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.programa.length} Progra. Seleccionados` }</em>
                </MenuItem>
                {props.programa.map((item, index) => (
                  <MenuItem key= { index } value = { item.relacion.id }>{`${item.relacion.nombre}`}</MenuItem>
                ))}
                </Select>
            </FormControl>
            <FormHelperText>{ props.getError('programa', props.errores) }</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Link de ORCID o CVLAC  (en caso de tenerlo)"
              //error = { props.getError('cvlac', props.errores).length > 0}
              //helperText = { props.getError('cvlac', props.errores) }
              fullWidth
              value={props.cvlac} 
              onChange={handleOnchange}
              name="cvlac"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Link Google Scholar o LinkedIn (en caso de tenerlo)"
              //error = { props.getError('correo', props.errores).length > 0}
              //helperText = { props.getError('correo', props.errores) }
              fullWidth
              value={props.scholar} 
              onChange={handleOnchange}
              name="scholar"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Link ResearchGate (en caso de tenerlo)"
              //error = { props.getError('correo', props.errores).length > 0}
              //helperText = { props.getError('correo', props.errores) }
              fullWidth
              value={props.researchGate} 
              onChange={handleOnchange}
              name="researchGate"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Link academia.edu (en caso de tenerlo)"
              //error = { props.getError('correo', props.errores).length > 0}
              //helperText = { props.getError('correo', props.errores) }
              fullWidth
              value={props.academiaEdu} 
              onChange={handleOnchange}
              name="academiaEdu"
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              id="standard-multiline-flexible"
              label="Indique sus experiencias en investigación y motivación para vincularse a un grupo de semillero. "
              error = { props.getError('experiencia', props.errores).length > 0}
              helperText = { props.getError('experiencia', props.errores) }
              fullWidth
              multiline
              maxRows={4}
              value={props.experiencia} 
              onChange={handleOnchange}
              name="experiencia"
            />
          </Grid>
          <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={props.semilleroAdjunto} onChange={handleChangeAdjunto} name="checkedG" />}
            label="¿Realizó el Seminario de Investigación Adjunto?"
          />
          </Grid>
          {props.semilleroAdjunto &&
            <Grid item xs={12}>
              <InputFile label='Adjuntar Soporte de Seminario' id='adj_seminario' value={props.adjSeminario} required={props.semilleroAdjunto} error={props.getError('adj_seminario', props.errores) > 0} helperText = { props.getError('adj_seminario', props.errores) }/>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={props.metodologiaInves} onChange={handleChangeMetodologia} name="checkedG" />}
              label="¿Cursó alguna asignatura de investigación? "
            />
          </Grid>
          {props.metodologiaInves &&
            <Grid item xs={12}>
              <InputFile label='Adjunte historial académico generado por SICUC ' id='adj_metodologia' value={props.adjMetodologia} required={props.metodologiaInves} error={ props.getError('adj_metodologia', props.errores) > 0} helperText = { props.getError('adj_metodologia', props.errores) }/>
            </Grid>
          }
        </Grid>
      </div>
    )
}

const ListarSemilleros = (props) => {

  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [fila, setFila] = React.useState('');
  
  const [pos, setPos] = React.useState('Información del Semillero');
  const [cargando, setCargando] = React.useState(false)
  const classes = useStyles();
  let array = []

  const handleToggle = (value) =>{
    const seleccionado = props.checked.indexOf(value);
    if (seleccionado === -1) array.push(value);
    else array.splice(seleccionado, 1);
    
    props.setChecked(array)
  }
  const acciones = (data) => {
    const openModalInfo = (data) => {
      console.log(data);
      setFila(data)
      setOpenModalInfo(true)
    }
    const Detalle = (
      <div>
        <Grid container spacing={2}>
            <Checkbox edge="end" onChange={() => handleToggle(data.id)} checked={props.checked.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': data.id }} />
            <Tooltip title="Detalle Semillero" arrow>
              <IconButton className={classes.colorEmmma} onClick={() => openModalInfo(data)}  > <MenuBookIcon /></IconButton>
            </Tooltip>
     
        </Grid>

      </div>
    );
    return <>{Detalle}</>;
  };
 
  return (
<div>
      {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />
        :
    <div>
      <ListarDatos
      id="tbl_listar_semilleros"
      avatar={({ nombre }) => nombre.charAt(0)}
      datos={props.listSemilleros}
      opciones={true}
      buscar={true}
      acciones={(row) => acciones(row)}
      titulo={"Lista de Semilleros de Investigación Seleccione su Semillero"} 
      fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
      filas={[
        {
          'mostrar': ({ id }) => `${id}`,
          'id': 'id', 'enLista': false
        },
        {
          'mostrar': ({ nombre }) => `${nombre}`,
          'id': 'nombre', 'enLista': false
        },
        {
          'mostrar': ({solicitud}) => `${solicitud.estado_actual.nombre}`,
          'id': 'estado_actual', 'enLista': true
        },
        {
          'mostrar': ({semillero_programa_departamento_investigacion}) => `${semillero_programa_departamento_investigacion[0].nombre}`,
          'id': 'Programa/Departamento', 'enLista': true
        },
        {
          'mostrar': ({usuario_registro}) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido}`,
          'id': 'Profesor', 'enLista': true
        },
        { 'id': 'descripcion', 'enLista': false },
      ]}
    />
  </div>}
      {openModalInfo == true ? <SemillerosListSemi
        openModalInfo={openModalInfo}
        setOpenModalInfo={setOpenModalInfo}

        row={fila}
        semiActual={fila}
        
        pos={pos}
        setPos={setPos}

        profesor={true}

        vistaEstudiante={true}
      /> : ''}
    </div>
  )
}
