import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Typography,
  ListItemText,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Select,
} from "@material-ui/core";
import AppBarModal from "../../general/AppBarModal";
import moment from "moment";
import { DetalleMenu } from "./MatriculasMenu";
import {
  consulta,
  api,
  valor_peso,
  crear_form_data,
  formulario,
  mostrarError,
} from "../../../global/js/funciones";
import ListarDatos from "./../../general/ListarDatos";
import { BtnDetalle, BtnCancelar, BtnForm, BtnGestionar, BtnVer } from "../../general/BotonesAccion";
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from "./../../../global/imagenes/emma_w.png";
import { obtenerPermisosValor } from "../../../global/js/funciones";

import { mostrarModalEncuestaGraduados, showModalState } from '../../../redux/actions/actMatriculas';
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { connect } from 'react-redux';
import { ModalDetalleEncuesta } from "./grados/encuesta_graduados/ModalDetalleEncuesta";

function ModalObservaciones({
  modal,
  mostrarmodal,
  titulo_carg,
  cargando,
  guardarinfo,
}) {
  return (
    <Dialog
      open={modal}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => mostrarmodal(false)}
    >
      <AppBarModal
        titulo="Negar Materia"
        mostrarModal={mostrarmodal}
        titulo_accion=""
      />
      <DialogContent className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje={titulo_carg}
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
          />
        ) : (
          <Grid item xs={12} md={12}>
            <FormControl
              className="form-control"
              style={{ margin: "15px 0px 40px 0" }}
            >
              <TextField
                id="txtObservacionMateria"
                label="Observaciones"
                placeholder="Escriba el motivo de rechazo de la materia"
                multiline
              />
            </FormControl>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Guardar" callback={() => guardarinfo()} />
        <BtnForm texto="Cerrar" callback={() => mostrarmodal(false)} />
      </DialogActions>
    </Dialog>
  );
}

class ModalDetalleSolicitud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estados: [],
      menciones: [],
      encuesta: [],
      adjuntos: [],
      materias: [],
      info: [],
      cargando: false,
      materiaActual: 0,
      titulo_carg: "Cargando",
      estadoCancelar: null,
      observacionReversion: null,
      idMatricula: null,
      actualizacion: [],
      certificados:[],
      openModalEncuesta: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { opcion, matricula, cambiarEstado } = this.props;
    if (
      prevProps.opcion !== opcion ||
      prevProps.matricula.id !== matricula.id
    ) {
      this.setState({ cargando: true });
      switch (opcion) {
        case "estados":
          const estados = await this.getEstadosMatricula(matricula.id);
          this.setState({ estados });
          break;
        case "linea":
          const estados2 = await this.getEstadosMatricula(matricula.id);
          this.setState({ estados: estados2 });
          break;
        case "menciones":
          const menciones = await this.getMencionesMatricula(matricula.id);
          this.setState({ menciones });
          break;
        case "adjuntos":
          const adjuntos = await this.getAdjuntosMatricula(matricula.id);
          this.setState({ adjuntos });
          break;
        case "documentos":
          const archivos = await this.getDocumentosMatricula(matricula.id);
          this.setState({ archivos });
          break;
        case "materias":
          const materias = await this.getMateriasMatricula(matricula.id);
          this.setState({ materias });
          break;
        case "detalle":
          const proceso = await this.getDetalleMatricula(matricula.id);
          if(matricula.tipo.codigo === "Mat_Pro_Act_Datos" || matricula.tipo.codigo === "Mat_Pro_Certi"){
            matricula.actualizacion_datos = proceso.actualizacion_datos;
          }
          cambiarEstado({ proceso });
          break;
        case "info":
          const info = await this.getInfoSolicitudMatricula(matricula.id);
          this.setState({ info });
          break;
        case "actualizacion":
          const actualizacion = await this.getActualizacionMatricula(matricula.id);
          this.setState({ actualizacion });
          break;
        case "certificados":
          const certificados = await this.getCertificadosMatricula(matricula.id);
          this.setState({ certificados });
          break;
        case "encuesta":
          const encuesta = await this.getEncuestaMatricula(
            matricula.id,
            matricula.usuario_registro.id,
            matricula.id
          );
          for (let index = 0; index < encuesta.length; index++) {
            if (encuesta[index].tipo == "tipo2") {
              let aux = await obtenerPermisosValor(encuesta[index].pregunta.id);
              let respuestasId = encuesta[index].respuesta.split(",");
              let respuestaString = "";
              for (let index2 = 0; index2 < respuestasId.length; index2++) {
                respuestaString +=
                  aux.find(
                    (element) => element.secundario.id == respuestasId[index2]
                  ).secundario.nombre + ", ";
              }
              encuesta[index].respuesta = respuestaString;
            }
            if (encuesta[index].tipo == "tipo1") {
              let aux = await obtenerPermisosValor(encuesta[index].pregunta.id);
              encuesta[index].respuesta = aux.find(
                (element) => element.secundario.id == encuesta[index].respuesta
              ).secundario.nombre;
            }
          }
          this.setState({ encuesta });
          break;
        default:
          break;
      }
      this.setState({ cargando: false });
    }
  }

  guardarInfoMateria = async () => {
    let { actualizarMensaje, setOpenObs, matricula } = this.props;
    let motivo = document.getElementById("txtObservacionMateria").value;
    if (!motivo) {
      actualizarMensaje({
        titulo: "Por favor escribir el motivo de rechazo",
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
    } else {
      this.setState({ cargando: true, titulo_carg: "Guardando..." });
      let { materiaActual } = this.state;
      let data = { observaciones: motivo, negado: 1 };
      let form = await crear_form_data(data);

      formulario(
        `api/v1.0/matriculas/${materiaActual}/guardar`,
        form,
        "post",
        (error, estado, resp) => {
          let titulo = "Ha ocurrido un error, contacte con el administrador.",
            tipo = "error";
          if (!error) {
            if (estado === 200) {
              tipo = "success";
              titulo = resp.titulo;
              setOpenObs(false);
              this.getMateriasMatricula(matricula.id).then((materias) =>
                this.setState({ materias })
              );
            } else {
              tipo = "info";
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          this.setState({ cargando: false, titulo_carg: "Cargando" });
        }
      );
    }
  };

  getEncuestaMatricula = (solicitud, usuario, matricula) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/config/encuesta/${1}/${usuario}/${matricula}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getMencionesMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/menciones`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getEstadosMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/estados`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getMateriasMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/cursos`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getAdjuntosMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/adjuntos`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getDocumentosMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/documentos`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getInfoSolicitudMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/info`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getActualizacionMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/actualizacion`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getCertificadosMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/certificados`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getDetalleMatricula = (id) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}/actualizacion`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  getDetalleMatricula = async (id) => {
    const consulta2 = await new Promise((resolve) => {
      consulta(
        `api/v1.0/matriculas/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
    return consulta2;
  };

  pintarMenciones = () => {
    const { menciones } = this.state;
    return (
      <ListarDatos
        datos={menciones}
        cargando={this.state.cargando}
        id="tbl_estados_credito"
        avatar={({ mencion: { nombre } }) => nombre[0]}
        fila_principal={({ mencion: { nombre } }) => nombre}
        filas={[
          {
            id: "fecha_registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha Registro : ",
          },
          {
            id: "usuario_registro",
            mostrar: ({
              usuario_registro: {
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              },
            }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
          },
        ]}
      />
    );
  };

  pintarEstados = () => {
    const { estados, observacionReversion } = this.state;
    const { showModalState, modalState } = this.props;
    const obtenerColor = (codigo) => {
      let color = "white";
      const colores = {
        Mat_Env: { backgroundColor: "#f5f5f5", color: "#757575" },
        Mat_Rec: { backgroundColor: "#b71c1c", color },
        Mat_Ter: { backgroundColor: "#009688", color },
        Mat_Sol_Exo: { backgroundColor: "#009688", color },
        Mat_Pro_Sol_Cam_Fec: { backgroundColor: "#009688", color },
        Mat_Rev: { backgroundColor: "#ffc107", color },
        Mat_Pro: { backgroundColor: "#ffc107", color },
        Mat_Com_Env: { backgroundColor: '#DBB62C', color },
        Mat_Pag: { backgroundColor: "#3498db", color },
        Mat_En_Est: { backgroundColor: "#AFF9DB", color: "#000"  },
        Mat_Aval_Fac_Rein_Tran: { backgroundColor: '#5cb85c', color },
        Mat_Act_Pen: { backgroundColor: "#ffc107", color },
        Mat_Act_Rev_Are: { backgroundColor: '#3498db', color },
        Mat_Rein_Tran_Doc_Ok: { backgroundColor: '#2950a6', color },
        Mat_Rein_Tran_Est: { backgroundColor: '#2950a6', color },
        Mat_cer_pago_vol: { backgroundColor: "#ACC7A1", color: "#000"  },
        Mat_cer_val_pago: { backgroundColor: "#C7B713", color },
        Mat_cer_val_soli: { backgroundColor: "#2AF40A", color },
        Mat_Rein_Tran_Est_Hom_Apro: { backgroundColor: '#2AF40A', color },
        Mat_Rein_Tran_Est_Hom_Recha: { backgroundColor: '#0d47a1', color },
        Mat_Rein_Tran_Apr: { backgroundColor: '#ec9c06', color },
  
      };
      return colores[codigo];
    };

    const acciones = (id, valorf, valora, matricula) => {
      const { usuario, modalDetEncuestaGraduados } = this.props;
      // let admin_or_mod = (perfilesUsuario.includes("Per_Adm_Mat_Mod") || perfilesUsuario.includes("Per_Adm"))
      const onClickGestionar = id => {
        this.setState({ estadoCancelar: id, idMatricula: matricula })
        showModalState(true);
      }

      const onClickAbrir = () => {
        this.setState({ idMatricula: matricula, openModalEncuesta: true });
      }

      let gestionar = (valorf === "aprobado" && valora !== '1') && usuario &&  (usuario.perfil.codigo === 'Per_Adm' || usuario.perfil.codigo === 'Per_Adm_Mat_Mod')&& <BtnCancelar callback={() => onClickGestionar(id)} color='#900807' texto='Gestionar' />;
      let detalleEncuesta = (valorf === "aprobado" && valora === '1') && usuario &&  (usuario.perfil.codigo === 'Per_Adm' || usuario.perfil.codigo === 'Per_Adm_Mat_Mod' || usuario.perfil.codigo === 'Per_Pos_Mat') && <BtnVer callback={() => onClickAbrir()} color='#01579b' texto='Ver' />;
      return <div>{gestionar}{detalleEncuesta}</div>;
    }

    return (
      <>
        <ListarDatos
          datos={estados}
          cargando={this.state.cargando}
          // opciones={true}
          acciones={({ estado_matricula: { id, valorf, valora }, matricula }) => acciones(id, valorf, valora, matricula)}
          id="tbl_estados_credito"
          avatar={({ estado_matricula: { nombre } }) => nombre[0]}
          color_avatar={({ estado_matricula: { codigo } }) =>
            obtenerColor(codigo)
          }
          fila_principal={({ estado_matricula: { nombre } }) => nombre.toUpperCase()}
          filas={[
            {
              id: "fecha_registro",
              mostrar: ({ fecha_registro }) =>
                moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
              nombre: "Fecha Registro : ",
            },
            {
              id: "usuario_registro",
              mostrar: ({
                usuario_registro: {
                  primer_nombre,
                  primer_apellido,
                  segundo_apellido,
                },
              }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
            },
            {
              id: "observaciones",
              mostrar: ({ observaciones }) => observaciones,
              nombre: "Observaciones : ",
            },
            {
              id: "link_hep",
              mostrar: ({ link_hep }) => link_hep ? <a href={link_hep} target="_blank">{link_hep}</a> : '',
            },
          ]}
        />
        
        <Dialog open={modalState} fullWidth={true} maxWidth='sm' onClose={() => showModalState(false)}>
          <AppBarModal
            titulo='¡ Revertir !'
            mostrarModal={showModalState}
            accion={() => showModalState(false)}
            titulo_accion='CERRAR'
          />
          <DialogContent style={{ padding: 25 }} className='scroll'>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className='form-control'>
                  {/* <InputLabel>Motivo de la Reversión</InputLabel> */}
                  <Grid item xs = { 12 } md = { 12 }>
                    <FormControl className = "form-control" >
                      <TextField
                        value = { observacionReversion }
                        id = "observacionReversion"
                        label = "Digite observaciones de la solicitud"
                        name = "observacionReversion"
                        multiline
                        onChange = { (e) => {this.setState({observacionReversion: e.target.value})} }
                      />
                    </FormControl>
              </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto='Cerrar' callback={() => showModalState(false)} />
            <BtnForm texto = "GUARDAR" callback = { () => this.revertirSolicitud() } />
          </DialogActions>
        </Dialog>
        
      </>
    );

    
  };

  revertirSolicitud = async () => {
    let {estadoCancelar, idMatricula, observacionReversion} = this.state;
    let data = {estadoCancelar, idMatricula, observacionReversion};
    await this.gestionarReversion(data);
  }

  gestionarReversion = (data) => {
    let {actualizarMensaje, matricula, showModalState} = this.props;
    return new Promise((resolve) => {
      consulta( `api/v1.0/matriculas/gestionarReversion`, data, "put", async (error, estado, resp) => {
          let title = "Ha ocurrido un error, contacte con el administrador.";
          let icon = "error";
          if (estado === 200) {
            icon = "success";
            title = resp.titulo;
            actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000, });
          } else {
            icon = "info";
            title = resp.titulo ? resp.titulo : mostrarError(resp);
            actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000, });
          }

          showModalState(false)
          const estados = await this.getEstadosMatricula(matricula.id);
          this.setState({ estados });
          this.pintarEstados()
          resolve(resp);
        }
        );
      });

  }

  pintarMaterias = () => {
    const { matricula, gestion, setOpenObs } = this.props;
    const { materias } = this.state;

    let total = 0;
    materias.forEach((mat) => {
      if (mat.negado === "0") {
        total += parseInt(mat.curso.valora);
      }
    });

    const acciones = (materia) => {
      let {
        estado_solicitud: { codigo },
        permiso,
      } = matricula;
      let { negado } = materia;
      const onClickNegar = (datos) => {
        setOpenObs(true);
        this.setState({ materiaActual: datos.id });
      };

      const negar = codigo === "Mat_Env" &&
        permiso === "1" &&
        gestion == 1 &&
        negado === "0" && (
          <BtnCancelar callback={() => onClickNegar(materia)} texto="Negar" />
        );
      return <div>{negar}</div>;
    };

    let filas = [];
    if (matricula && matricula.tipo.codigo === "Mat_Pro_Cre_ext") {
      filas = [
        {
          id: "creditosMateria",
        },
        {
          id: "grupoMateria",
        },
      ];
    } else if (matricula && matricula.tipo.codigo === "Mat_Pro_Dip") {
      filas = [
        {
          id: "valor",
          mostrar: ({ curso: { valorb } }) => valor_peso(valorb),
          nombre: "Valor: ",
        },
        {
          id: "observaciones",
          mostrar: ({ observaciones }) => observaciones,
          nombre: "Observaciones: ",
        },
      ];
    } else if (
      matricula &&
      (matricula.tipo.codigo === "Mat_Pro_Int" ||
        matricula.tipo.codigo === "Mat_Pro_Pre")
    ) {
      filas = [
        {
          id: "creditos",
          mostrar: ({ curso: { valora } }) => valora,
          nombre: "Créditos: ",
        },
        {
          id: "observaciones",
          mostrar: ({ observaciones }) => observaciones,
          nombre: "Observaciones: ",
        },
      ];
    } else {
      filas = [
        {
          id: "creditos",
          mostrar: ({ curso: { valora } }) => valora,
          nombre: "Créditos: ",
        },
        {
          id: "valorCredito",
          mostrar: ({ curso: { valorb } }) => valor_peso(valorb),
          nombre: "Valor Crédito: ",
        },
        {
          id: "Valor Total",
          mostrar: ({ curso: { valora, valorb } }) =>
            valor_peso(parseInt(valora) * parseInt(valorb)),
          nombre: "Valor: ",
        },
        {
          id: "observaciones",
          mostrar: ({ observaciones }) => observaciones,
          nombre: "Observaciones: ",
        },
      ];
    }

    const filaPrincipal = (data) => {
      if (matricula && matricula.tipo.codigo === "Mat_Pro_Cre_ext") {
        return data.nombreMateria;
      } else if (
        (matricula && matricula.tipo.codigo === "Mat_Pro_Dip") ||
        matricula.tipo.codigo === "Mat_Pro_Pos" ||
        matricula.tipo.codigo === "Mat_Pro_Pre" ||
        matricula.tipo.codigo === "Mat_Pro_Int"
      ) {
        return data.curso.nombre;
      }
      return data.nombre;
    };

    const avatar = (data) => {
      if (matricula && matricula.tipo.codigo === "Mat_Pro_Cre_ext") {
        return data.nombreMateria.charAt(0);
      } else if (
        matricula &&
        (matricula.tipo.codigo === "Mat_Pro_Dip" ||
          matricula.tipo.codigo === "Mat_Pro_Pos" ||
          matricula.tipo.codigo === "Mat_Pro_Pre" ||
          matricula.tipo.codigo === "Mat_Pro_Int")
      ) {
        return data.curso.nombre.charAt(0);
      } else {
        return data.nombre.charAt(0);
      }
    };

    return (
      <>
        {matricula.tipo.codigo === "Mat_Pro_Dip" && matricula.tipo.codigo === "Mat_Pro_Sol_Rein_Tran" && (
          <ListItemText>
            <Typography
              style={{
                color: "black",
                textTransform: "uppercase",
                marginTop: "10px",
              }}
              component="span"
              variant="body2"
              color="textSecondary"
            >
              {`Total de Creditos Aprobados: ${total}`}
            </Typography>
          </ListItemText>
        )}
        <ListarDatos
          datos={materias}
          cargando={this.state.cargando}
          id="tbl_estados_credito"
          avatar={avatar}
          color_avatar={() => ({
            backgroundColor: "#f5f5f5",
            color: "#757575",
          })}
          fila_principal={filaPrincipal}
          filas={filas}
          acciones={(data) => acciones(data)}
        />
      </>
    );
  };

  pintarAdjuntos = () => {
    const { adjuntos } = this.state;
    const acciones = ({ archivo }) => {
      let detalle = (
        <a
          href={`${api}${archivo}`}
          target="_blank"
          rel="noopener noreferrer"
          className="sin_decorador"
        >
          <BtnDetalle callback={() => {}} color="#01579b" texto="Abrir" />
        </a>
      );
      return <div>{detalle}</div>;
    };

    return (
      <ListarDatos
        datos={adjuntos}
        cargando={this.state.cargando}
        id="tbl_adjuntos_credito"
        avatar={({ nombre_archivo }) => nombre_archivo.charAt(0)}
        acciones={(data) => acciones(data)}
        color_avatar={() => ({ backgroundColor: "#f5f5f5", color: "#757575" })}
        fila_principal="nombre_archivo"
        filas={[
          {
            id: "fecha_registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha Registro : ",
          },
          {
            id: "usuario_registro",
            mostrar: ({
              usuario_registro: {
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              },
            }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
          },
        ]}
      />
    );
  };

  pintarArchivos = () => {
    const { archivos } = this.state;
    const acciones = ({ archivo }) => {
      let detalle = (
        <a
          href={`${api}${archivo}`}
          target="_blank"
          rel="noopener noreferrer"
          className="sin_decorador"
        >
          <BtnDetalle callback={() => {}} color="#01579b" texto="Abrir" />
        </a>
      );
      return <div>{detalle}</div>;
    };

    return (
      <ListarDatos
        datos={archivos}
        cargando={this.state.cargando}
        id="tbl_adjuntos_credito"
        avatar={({ nombre_archivo }) => nombre_archivo.charAt(0)}
        acciones={(data) => acciones(data)}
        color_avatar={() => ({ backgroundColor: "#f5f5f5", color: "#757575" })}
        fila_principal="nombre_archivo"
        filas={[
          {
            id: "fecha_registro",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha Registro : ",
          },
          {
            id: "usuario_registro",
            mostrar: ({
              usuario_registro: {
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              },
            }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
          },
        ]}
      />
    );
  };

	DetalleSolicitud = () => {
		const { matricula, admin } = this.props;

		return (
			<Table>
				{matricula &&
					matricula.tipo && (
						<TableBody>
							{admin && (
								<>
									<TableRow>
										<TableCell>Solicitante</TableCell>
										<TableCell>
											{`${matricula.usuario_registro.primer_nombre} ${matricula.usuario_registro
												.segundo_nombre} ${matricula.usuario_registro.primer_apellido} ${matricula
													.usuario_registro.segundo_apellido}`}
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell>Identificación</TableCell>
										<TableCell>{`${matricula.usuario_registro.identificacion}`}</TableCell>
									</TableRow>
                  <TableRow>
                    <TableCell>Teléfono</TableCell>
                    <TableCell>{`${matricula.usuario_registro.telefono}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Celular</TableCell>
                    <TableCell>{`${matricula.usuario_registro.celular}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Correo Personal</TableCell>
                    <TableCell>{`${matricula.usuario_registro.correo_personal}`}</TableCell>
                  </TableRow>
									<TableRow>
										<TableCell>Correo Institucional</TableCell>
										<TableCell>{`${matricula.usuario_registro.correo}`}</TableCell>
									</TableRow>
								</>
							)}



							<TableRow>
								<TableCell>Tipo de Solicitud:</TableCell>
								<TableCell>{matricula.tipo.nombre}</TableCell>
							</TableRow>

              <TableRow>
                <TableCell>Programa</TableCell>
                <TableCell>{`${matricula.programa.nombre}`}</TableCell>
              </TableRow>

              {/* Info que solo se muestra en el tipo de reintegro */}
							{matricula.tipo.codigo == 'Mat_Pro_Sol_Rein_Tran' && (
								<>
									<TableRow>
										<TableCell>Tipo de Estudio:</TableCell>
										<TableCell>{matricula.tipo_estudio.nombre}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Programa Procedencia:</TableCell>
										<TableCell>{matricula.programa_procedencia}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Institución Procedencia:</TableCell>
										<TableCell>{matricula.institucion_procedencia}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Número de Créditos:</TableCell>
										<TableCell>{matricula.numero_creditos}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lugar de Nacimiento:</TableCell>
										<TableCell>{matricula.usuario_registro.lugar_nacimiento}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Fecha de Nacimiento:</TableCell>
										<TableCell>{matricula.usuario_registro.fecha_nacimiento}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Dirección:</TableCell>
										<TableCell>{matricula.usuario_registro.direccion}</TableCell>
									</TableRow>
                  { matricula.eps &&
                    <TableRow>
                      <TableCell>EPS:</TableCell>
                      <TableCell>{matricula.eps.nombre}</TableCell>
                    </TableRow>
                  }
								</>
							)}

              {/* Info que solo se muestra en el tipo de grado */}
              {matricula.tipo.codigo == 'Mat_Pro_Sol_Gra' && (
                <>
                  <TableRow>
                      <TableCell>Ciudad de Expedición:</TableCell>
                      <TableCell>{matricula.usuario_registro.lugar_expedicion}</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>Fecha de Expedición:</TableCell>
                      <TableCell>{matricula.usuario_registro.fecha_expedicion}</TableCell>
                  </TableRow>
                </>
              )}

                {/* Info que solo se muestra en el tipo de correccion */}
                {(matricula.tipo.codigo == 'Mat_Pro_Cor_Nota' && matricula.correccion_notas && matricula.correccion_notas.nombre_materia) && (
                <>
                  <TableRow>
                    <TableCell>Materia:</TableCell>
                    <TableCell>{matricula.correccion_notas.nombre_materia}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Codigo Materia:</TableCell>
                    <TableCell>{matricula.correccion_notas.cod_materia}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Grupo:</TableCell>
                    <TableCell>{matricula.correccion_notas.nombre_grupo}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Codigo Pensum:</TableCell>
                    <TableCell>{matricula.correccion_notas.cod_pensum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Motivo:</TableCell>
                    <TableCell>{matricula.correccion_notas.motivo.nombre}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Comentarios: </TableCell>
                    <TableCell>{matricula.correccion_notas.comentario}</TableCell>
                  </TableRow>
                </>
              )}

							<TableRow>
								<TableCell>Estado de la Solicitud:</TableCell>
								<TableCell>{matricula.estado_solicitud.nombre}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fecha Solicitud:</TableCell>
								<TableCell>{moment(matricula.fecha_registro).format('YYYY-MM-DD, h:mm a')}</TableCell>
							</TableRow>
              
							{!admin && matricula.gestor && (
								<>
									<TableRow>
										<TableCell>Asesor Encargado</TableCell>
										<TableCell>{`${matricula.gestor.primer_nombre} ${matricula.gestor.primer_apellido} ${matricula.gestor.segundo_apellido}`}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Correo Asesor</TableCell>
										<TableCell>{matricula.gestor.correo}</TableCell>
									</TableRow>
								</>
							)}

							{matricula.motivo_negado && (
								<TableRow>
									<TableCell>Observaciones:</TableCell>
									<TableCell>{matricula.motivo_negado}</TableCell>
								</TableRow>
							)}

						</TableBody>
					)}
			</Table>
		);
	};

  pintarActualizacion = () => {
		const { actualizacion } = this.state;
    const { matricula } = this.props;

    return (
			<Table>
				{
					actualizacion && actualizacion.id && matricula.id && (
						<TableBody>
							<TableRow>
								<TableCell>Autorización para el Tratamiento de Datos Personales</TableCell>
								<TableCell>
									{matricula.autorizo == 1 ? 'SI' : 'NO'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre Completo</TableCell>
								<TableCell>
                  {`${actualizacion.primer_nombre} ${actualizacion.segundo_nombre} ${actualizacion.primer_apellido} ${actualizacion.segundo_apellido}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Tipo de Identificación</TableCell>
								<TableCell>
									{`${actualizacion.tipo_identificacion}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Identificación</TableCell>
								<TableCell>
									{`${actualizacion.identificacion}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Telefono</TableCell>
								<TableCell>
									{`${actualizacion.telefono}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Celular</TableCell>
								<TableCell>
									{`${actualizacion.celular}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Correo Personal</TableCell>
								<TableCell>
									{`${actualizacion.correo_personal}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Correo Institucion</TableCell>
								<TableCell>
									{`${matricula.usuario_registro.correo}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Direccion</TableCell>
								<TableCell>
									{`${actualizacion.direccion}`}
								</TableCell>
							</TableRow>
              { actualizacion.eps &&
                <TableRow>
                  <TableCell>EPS</TableCell>
                  <TableCell>
                    {`${actualizacion.eps.nombre}`}
                  </TableCell>
                </TableRow>
              }
							<TableRow>
								<TableCell>Departamento</TableCell>
								<TableCell>
									{`${actualizacion.departamento.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Ciudad</TableCell>
								<TableCell>
									{`${actualizacion.ciudad.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Tipo de Solicitud</TableCell>
								<TableCell>
									{`${matricula.tipo.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Programa</TableCell>
								<TableCell>
                {`${matricula.programa.nombre}`}
								</TableCell>
							</TableRow> 
							<TableRow>
								<TableCell>Estado Solicitud</TableCell>
								<TableCell>
                {`${matricula.estado_solicitud.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fecha Solicitud</TableCell>
								<TableCell>
                {moment(matricula.fecha_registro).format('YYYY-MM-DD, h:mm a')}
								</TableCell>
							</TableRow>
						</TableBody>
					)
				}
			</Table>
		);
	};

  pintarCertificado = () =>{
    const { certificados } = this.state;
    const { matricula } = this.props;
    return (
      <>
			<Table>
				{
					certificados && certificados.id && matricula.id && (
						<TableBody>
							<TableRow>
								<TableCell>Autorización para el Tratamiento de Datos Personales</TableCell>
								<TableCell>
									{matricula.autorizo == 1 ? 'SI' : 'NO'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre Completo</TableCell>
								<TableCell>
                  {`${matricula && matricula.usuario_registro.primer_nombre} ${matricula && matricula.usuario_registro.segundo_nombre} ${matricula && matricula.usuario_registro.primer_apellido} ${matricula && matricula.usuario_registro.segundo_apellido}`}
								</TableCell>
							</TableRow>
							{/* <TableRow>
								<TableCell>Tipo de Identificación</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.tipo_identificacion}`}
								</TableCell>
							</TableRow> */}
							<TableRow>
								<TableCell>Identificación</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.identificacion}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Telefono</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.telefono}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Celular</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.celular}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Correo Personal</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.correo_personal}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Correo Institucion</TableCell>
								<TableCell>
									{`${matricula.usuario_registro.correo}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Direccion</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.direccion}`}
								</TableCell>
							</TableRow>
							{/* <TableRow>
								<TableCell>Departamento</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.departamento.nombre}`}
								</TableCell>
							</TableRow> */}
							<TableRow>
								<TableCell>Ciudad</TableCell>
								<TableCell>
									{`${matricula && matricula.usuario_registro.lugar_residencia}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Tipo de Solicitud</TableCell>
								<TableCell>
									{`${matricula.tipo.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Programa</TableCell>
								<TableCell>
                {`${matricula.programa.nombre}`}
								</TableCell>
							</TableRow> 
              <TableRow>
								<TableCell>Certificado</TableCell>
								<TableCell>
                {`${certificados.certificado.nombre}`}
								</TableCell>
							</TableRow> 
              <TableRow>
								<TableCell>Tipo de Certificado</TableCell>
								<TableCell>
                {`${certificados.tipo_certificado.nombre}`}
								</TableCell>
							</TableRow> 
              <TableRow>
								<TableCell>Año</TableCell>
								<TableCell>
                {`${certificados.anno}`}
								</TableCell>
							</TableRow>
              <TableRow>
								<TableCell>Periodo Certificado</TableCell>
								<TableCell>
                {`${certificados.periodo_certificado.nombre}`}
								</TableCell>
							</TableRow>  
							<TableRow>
								<TableCell>Estado Solicitud</TableCell>
								<TableCell>
                {`${matricula.estado_solicitud.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fecha Solicitud</TableCell>
								<TableCell>
                {moment(matricula.fecha_registro).format('YYYY-MM-DD, h:mm a')}
								</TableCell>
							</TableRow>
						</TableBody>
					)
				}
			</Table>
      </>
		);
  };



  SolicitudGrado = () => {
		const { info } = this.state;
    const { matricula } = this.props;

    return (
			<Table>
				{
					info && info.id && (
						<TableBody>
							<TableRow>
								<TableCell>Autorización para el Tratamiento de Datos Personales</TableCell>
								<TableCell>
									{matricula.autorizo == 1 ? 'SI' : 'NO'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fecha de Grado</TableCell>
								<TableCell>
									{`${info.fechaGrado.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Aspirante a Grado de</TableCell>
								<TableCell>
									{`${info.aspirante.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre del programa a graduar</TableCell>
								<TableCell>
									{`${info.programa.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Periodo en que terminó académicamente</TableCell>
								<TableCell>
									{`${info.periodo}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre de la Opción de Grado Tipo 1</TableCell>
								<TableCell>
									{`${info.opcionGrado1.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre de la Opción de Grado Tipo 2</TableCell>
								<TableCell>
									{`${info.opcionGrado2.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Trabajo de Grado</TableCell>
								<TableCell>
									{`${info.trabajo_grado}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Dirección de Residencia</TableCell>
								<TableCell>
									{`${info.direccion_residencia}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Ciudad de Residencia</TableCell>
								<TableCell>
									{`${info.ciudad_residencia}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>País</TableCell>
								<TableCell>
									{`${info.pais}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Estrato Socio Económico</TableCell>
								<TableCell>
									{`${info.estrato.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fecha de Nacimiento</TableCell>
								<TableCell>
									{`${info.fecha_nacimiento}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Sexo</TableCell>
								<TableCell>
									{`${info.sexo.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Egresado de otro programa</TableCell>
								<TableCell>
                {info.egresado ? `${info.egresado.nombre}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Nombre del Programa</TableCell>
								<TableCell>
									{info.nombre_programa ? `${info.nombre_programa}` : 'N/A'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Año en que Graduó</TableCell>
								<TableCell>
									{info.ano_graduo ? `${info.ano_graduo}` : 'N/A'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Institución Educativa</TableCell>
								<TableCell>
									{info.institucion_educativa ? `${info.institucion_educativa}` : 'N/A'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Trabaja Actualmente</TableCell>
								<TableCell>
									{`${info.trabaja.nombre}`}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Actividad Laboral</TableCell>
								<TableCell>
									{info.trabaja.codigo == 'Tra_Si' ? `${info.actividadLaboral.nombre}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Empresa Donde Labora</TableCell>
								<TableCell>
									{info.empresa_labora ? `${info.empresa_labora}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Dirección</TableCell>
								<TableCell>
									{info.direccion ? `${info.direccion}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Cargo que Desempeña</TableCell>
								<TableCell>
									{info.cargo_desempena ? `${info.cargo_desempena}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Teléfonos</TableCell>
								<TableCell>
									{info.telefono_empresarial ? `${info.telefono_empresarial}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Correo Electrónico Empresarial</TableCell>
								<TableCell>
									{info.correo_empresarial ? `${info.correo_empresarial}` : ''}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Rango Salarial</TableCell>
								<TableCell>
									{info.trabaja.codigo == 'Tra_Si' ? `${info.rango.nombre}` : ''}
								</TableCell>
							</TableRow>
						</TableBody>
					)
				}
			</Table>
		);
	};

  buscarUltimo(Lista, listaCodigo, estado_aprobado) {
    let titulo = "Sin Procesar";

    for (const {estado_matricula: {nombre, codigo}} of Lista){
      if (codigo  == estado_aprobado) {
        titulo = nombre.toUpperCase();
        break;
      }

      if (listaCodigo.indexOf(codigo) >= 0) {
        titulo = nombre.toUpperCase();
      }
    }

    return titulo;
  }

  pintarEstadosLineaDelTiempo = () => {
    const { estados } = this.state;
    const { matricula } = this.props;

    return (
      <Table>
        {estados && matricula && (
          <TableBody>
            <TableRow>
              <TableCell>Tipo de Solicitud:</TableCell>
              <TableCell>{matricula.tipo.nombre}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Biblioteca:</TableCell>
              <TableCell>
                {this.buscarUltimo(estados, [
                  "Mat_Sol_Gra_Apr_Bib",
                  "Mat_Sol_Gra_Mul",
                  "Mat_Sol_Gra_Pen_Trab_Gra",
                  "Mat_Sol_Gra_Rev",
                ], 'Mat_Sol_Gra_Apr_Bib')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Facultad:</TableCell>
              <TableCell>
                {this.buscarUltimo(estados, [
                  "Mat_Sol_Gra_Apr_Fac",
                  "Mat_Sol_Gra_Pen_Aca",
                ], 'Mat_Sol_Gra_Apr_Fac')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Credito y cartera:</TableCell>
              <TableCell>
                {this.buscarUltimo(estados, [
                  "Mat_Sol_Gra_Apr_Paz_Sal",
                  "Mat_Sol_Gra_Pen_Cre",
                  "Mat_Sol_Gra_Paz_Sal_Ser",
                  "Mat_Sol_Gra_Paz_Sal_Cuc",
                  "Mat_Sol_Gra_No_Sis",
                ], 'Mat_Sol_Gra_Apr_Paz_Sal')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tecnologia:</TableCell>
              <TableCell>
                {this.buscarUltimo(estados, [
                  "Mat_Sol_Gra_Apr_Tec",
                  "Mat_Sol_Gra_Pre_Act",
                ], 'Mat_Sol_Gra_Apr_Tec')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Encuesta Graduados:</TableCell>
              <TableCell>
                <>
                  {this.buscarUltimo(estados, [
                    "Mat_Sol_Gra_Apr_Enc",
                    // "Mat_Sol_Gra_Pre_Act",
                  ], 'Mat_Sol_Gra_Apr_Enc')}
                </>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    );
  };

  EncuestaSolicitud = () => {
    const { encuesta } = this.state;
    return (
      <div>
        {encuesta.length > 0 ? (
          <Table>
            <TableBody>
              {encuesta.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.pregunta.nombre}:</TableCell>
                    <TableCell>{item.respuesta}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <TareasTerminadas
            mensaje="Bandeja vacia"
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
          />
        )}
      </div>
    );
  };

  pintar = () => {
    let { opcion, matricula } = this.props;
    let { cargando } = this.state;
    if (cargando)
      return (
        <TareasTerminadas
          mensaje="Cargando.."
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
        />
      );
    return (
      <div>
        {opcion === "detalle" && this.DetalleSolicitud()}
        {opcion === "info" && this.SolicitudGrado()}
        {opcion === "encuesta" && this.EncuestaSolicitud()}
        {opcion === "estados" && this.pintarEstados()}
        {opcion === "menciones" && this.pintarMenciones()}
        {opcion === "adjuntos" && this.pintarAdjuntos()}
        {opcion === "documentos" && this.pintarArchivos()}
        {opcion === "materias" && this.pintarMaterias()}
        {opcion === "linea" && this.pintarEstadosLineaDelTiempo()}
        {opcion === "actualizacion" && this.pintarActualizacion()}
        {opcion === "certificados" && this.pintarCertificado()}
      </div>
    );
  };

  render() {
    const {
      opcion,
      setOpen,
      open,
      cambiarEstado,
      gestion,
      openGestionar,
      openObs,
      setOpenObs,
      matricula,
    } = this.props;
    let { cargando, titulo_carg, openModalEncuesta } = this.state;
    return (
      <>
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth="md"
        >
          <AppBarModal
            titulo="Detalle Solicitud"
            mostrarModal={() => setOpen(false)}
            titulo_accion="Cerrar"
            accion={() => setOpen(false)}
          />
          <DetalleMenu
            seleccionar={(seleccion) => cambiarEstado(seleccion)}
            sele={opcion}
            matricula={matricula}
          />
          <DialogContent>{this.pintar()}</DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setOpen(false)}
              color="primary"
              type="button"
            >
              Cerrar
            </Button>
            {gestion == 1 && (
              <Button
                onClick={() => openGestionar(true)}
                color="primary"
                type="button"
              >
                Gestionar
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <ModalObservaciones
          modal={openObs}
          mostrarmodal={setOpenObs}
          cargando={cargando}
          titulo_carg={titulo_carg}
          guardarinfo={this.guardarInfoMateria}
        />
        <ModalDetalleEncuesta 
          open={openModalEncuesta}
          setOpen={(open) => this.setState({ openModalEncuesta: open })}
          matricula={matricula}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
	let { modalState, modalDetEncuestaGraduados } = state.redMatriculas;
	return {
		modalState,
    modalDetEncuestaGraduados,
	};
};

const mapDispatchToProps = {
	showModalState,
  mostrarModalEncuestaGraduados,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleSolicitud);
